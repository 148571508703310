import { gql } from '@apollo/client';

export const ARCHIVE_POLICY = gql`
  mutation ArchivePolicy($insuranceType: String!, $id: String!) {
    archivePolicy(insuranceType: $insuranceType, id: $id) {
      insuranceType
      id
    }
  }
`;

export const UNARCHIVE_POLICY = gql`
  mutation ArchivePolicy($insuranceType: String!, $id: String!) {
    unarchivePolicy(insuranceType: $insuranceType, id: $id) {
      id
    }
  }
`;

export const UPDATE_POLICY_CANCELATION_REQUEST = gql`
  mutation UpdatePolicyCancelationRequest(
    $insuranceType: String!
    $policyId: String!
    $id: String
    $activeUntil: String
    $createdAt: String
    $canceledOnStripeAt: String
    $requestSentToProviderAt: String
    $cancelationConfirmedByProviderAt: String
    $reason: String
  ) {
    updatePolicyCancelationRequest(
      insuranceType: $insuranceType
      policyId: $policyId
      id: $id
      activeUntil: $activeUntil
      createdAt: $createdAt
      canceledOnStripeAt: $canceledOnStripeAt
      requestSentToProviderAt: $requestSentToProviderAt
      cancelationConfirmedByProviderAt: $cancelationConfirmedByProviderAt
      reason: $reason
    ) {
      id
      activeUntil
      policyCancelationRequest {
        id
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
        cancelationConfirmedByProviderAt
        reason
      }
    }
  }
`;

export const UPDATE_USER_IN_POLICY = gql`
  mutation UpdateUserInPolicy($userId: String!, $policyId: String!) {
    updateUserInPolicy(userId: $userId, policyId: $policyId) {
      id
      user {
        id
        email
        dateOfBirth
        lastName
        firstName
      }
    }
  }
`;

export const UPLOAD_POLICY_DOCUMENT = gql`
  mutation UploadPolicyDocument(
    $id: String!
    $documents: [PolicyDocumentUpload!]!
  ) {
    uploadPolicyDocument(id: $id, documents: $documents) {
      archivedAt
      category
      friendlyName
      createdAt
      id
      blobName
      internalNotes
      isFromProvider
      isVisibleToCustomer
      isVisibleToHr
      isVisibleToProvider
      name
      resizedUrl
      url
    }
  }
`;

export const EDIT_POLICY_DOCUMENT = gql`
  mutation EditPolicyDocument(
    $category: String!
    $friendlyName: String!
    $id: String!
    $internalNotes: String
    $isVisibleToProvider: Boolean
    $isVisibleToHr: Boolean
    $isVisibleToCustomer: Boolean
  ) {
    editPolicyDocument(
      category: $category
      friendlyName: $friendlyName
      id: $id
      internalNotes: $internalNotes
      isVisibleToProvider: $isVisibleToProvider
      isVisibleToHr: $isVisibleToHr
      isVisibleToCustomer: $isVisibleToCustomer
    ) {
      archivedAt
      category
      friendlyName
      name
      isVisibleToProvider
      isVisibleToHr
      isVisibleToCustomer
      internalNotes
      id
    }
  }
`;

export const DELETE_POLICY_DOCUMENT = gql`
  mutation DeletePolicyDocument($id: String!) {
    deletePolicyDocument(id: $id) {
      id
    }
  }
`;

export const ARCHIVE_POLICY_DOCUMENT = gql`
  mutation ArchivePolicy($id: String!) {
    archivePolicyDocument(id: $id) {
      archivedAt
      id
    }
  }
`;

export const RESTORE_POLICY_DOCUMENT = gql`
  mutation RestorePolicyDocument($id: String!) {
    restorePolicyDocument(id: $id) {
      archivedAt
      id
    }
  }
`;

export const GENERATE_POLICY_DOCUMENTS_ARCHIVE = gql`
  mutation GeneratePolicyDocumentsArchive($id: String!) {
    generatePolicyDocumentsArchive(policyId: $id)
  }
`;

export const GET_POLICY_DOCUMENTS_ARCHIVE = gql`
  query Query($workflowId: String!) {
    getPolicyDocumentsArchive(workflowId: $workflowId)
  }
`;
