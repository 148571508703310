import { InformationRow, MultipleLineRow } from 'components/InformationCard';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';

const isValidObject = (value: unknown): value is Record<string, unknown> => {
  return (
    !!value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    !('code' in value)
  );
};

export const mapCompanyDetailsToInformationCard = (
  items: object,
  multipleLineKeys: string[],
  keysToHide: string[]
): InformationRow[] => {
  return Object.entries(items).flatMap(([key, value]) => {
    if (keysToHide.includes(key)) return [];

    if (isValidObject(value)) {
      if (multipleLineKeys.includes(key)) {
        const multipleLinesData = mapCompanyDetailsToMultipleLinesRow(
          value,
          keysToHide
        );
        return multipleLinesData.length
          ? [
              {
                id: key,
                title: upperFirst(lowerCase(key)),
                multipleLinesData,
                type: 'MULTIPLELINE',
                component: MultipleLineInformation,
              },
            ]
          : [];
      }
      return mapCompanyDetailsToInformationCard(
        value,
        multipleLineKeys,
        keysToHide
      );
    }

    return [];
  });
};

const mapCompanyDetailsToMultipleLinesRow = (
  nestedValue: object,
  keysToHide: string[]
): MultipleLineRow[] => {
  return Object.entries(nestedValue).flatMap(([key, value]) => {
    if (keysToHide.includes(key)) return [];

    if (isValidObject(value)) {
      return mapCompanyDetailsToMultipleLinesRow(value, keysToHide);
    }

    return [
      {
        label: upperFirst(lowerCase(key)),
        data:
          typeof value === 'object'
            ? JSON.stringify(value, null, ' ')
            : value?.toString(),
      },
    ];
  });
};
