import classNames from 'classnames';

export const policyCard = classNames(
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[20px]',
  'flex',
  'justify-between',
  'content-center',
  'transition',
  'duration-100',
  'ease-in'
);

export const leftContainer = classNames('flex', 'justify-between');

export const rightContainer = classNames('flex', 'self-center');

export const icon = classNames('w-[48px]', 'h-[48px]');

export const titleContainer = classNames(
  'ml-[12px]',
  'flex',
  'flex-col',
  'justify-center'
);

export const title = classNames('text-sm');

export const status = classNames('ml-[12px]');
