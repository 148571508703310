import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { ClaimFileType } from 'models/Claim';
import { ClaimDocumentCategory } from 'pages/claims/template/models';
import { useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { categoryOptions, typeOptions, visibilityOptions } from '../../data';
import { UPLOAD_CLAIM_DOCUMENT } from '../../graphql/mutations';
import { getUploadDocumentSchema } from './zodSchema';

interface Props {
  claimId: string;
  userId: string;
  refetchQuery: DocumentNode;
}

export const UploadButton = ({ claimId, userId, refetchQuery }: Props) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [open, setOpen] = useState(false);

  const [newDocuments, setNewDocuments] = useState<
    {
      id: string;
      file: File;
      friendlyName?: string;
      category?: ClaimDocumentCategory;
      internalNotes?: string;
      isVisibleToCustomer?: boolean;
      type?: ClaimFileType;
    }[]
  >([]);

  const [validationError, setValidationError] = useState<
    Record<
      string,
      Partial<{
        id: string;
        claimId: string;
        file: string;
        friendlyName: string;
        type: string;
        userId: string;
        category: string;
        isVisibleToCustomer: string;
        internalNotes: string;
      }>
    >
  >({});

  const [uploadDocument, { loading }] = useMutation(UPLOAD_CLAIM_DOCUMENT, {
    refetchQueries: [refetchQuery],
    onCompleted: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.documents.upload.success.title',
          'Document(s) were uploaded successfully.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.upload.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const resetForm = () => {
    setNewDocuments([]);
  };

  const handleUploadDocument = () => {
    if (!newDocuments || newDocuments.length === 0) return;
    let blockSubmit = false;

    setValidationError({});

    for (const document of newDocuments) {
      const variables = {
        ...document,
        claimId,
        userId,
      };

      const validation = getUploadDocumentSchema(document?.type).safeParse({
        ...variables,
      });

      if (!validation.success) {
        setValidationError({
          ...validationError,
          [document.id]: retrieveZodErrorMessages(validation.error),
        });
        blockSubmit = true;
      }
    }

    if (blockSubmit) return;

    uploadDocument({
      variables: {
        claimId,
        userId,
        documents: newDocuments.map((document) => ({
          file: document.file,
          category: document.category,
          friendlyName: document.friendlyName,
          internalNotes: document.internalNotes,
          isVisibleToCustomer: document.isVisibleToCustomer,
          type: document.type,
        })),
      },
    });
  };

  const handleOpenModal = () => {
    resetForm();
    setValidationError({});
    setOpen(true);
  };

  const getVisibility = (id: string) => {
    const newDocument = newDocuments?.find((document) => document.id === id);
    return visibilityOptions.filter(
      (option) => newDocument?.isVisibleToCustomer && option.id === 'CUSTOMER'
    );
  };

  const updateVisibility = (newSelected: SelectMenuOption[], id: string) => {
    const customer = newSelected.find((option) => option.id === 'CUSTOMER');

    setNewDocuments(
      newDocuments.map((document) => {
        if (document.id === id) {
          return {
            ...document,
            isVisibleToCustomer: !!customer,
          };
        }
        return document;
      })
    );
  };

  const addNewFile = (newFile: File) => {
    const id = uuidv4();
    setNewDocuments([
      ...newDocuments,
      {
        id,
        file: newFile,
        isVisibleToCustomer: true,
      },
    ]);
  };

  return (
    <>
      <Button
        type="button"
        className="w-[88px]"
        onClick={() => handleOpenModal()}
        buttonType="secondary"
      >
        {t('admin.documents.action.upload.label', 'Upload')}
      </Button>
      <Modal
        title={t('admin.documents.uploadModal.title', 'Upload document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t(
          'admin.documents.uploadModal.confirm.label',
          'Confirm'
        )}
        loading={loading}
        handleConfirm={handleUploadDocument}
        disabled={!newDocuments || newDocuments.length === 0}
        scrollable
      >
        <div className="flex flex-col space-y-4">
          {newDocuments.map((document) => {
            const isDocumentTypeFeatherOrProvider =
              document?.type === 'FEATHER_UPLOADS' ||
              document?.type === 'PROVIDER_UPLOADS';

            return (
              <>
                <FileDropzone
                  uploadedFile={document.file}
                  onFileSelect={() => {}}
                  onFileDelete={() =>
                    setNewDocuments(
                      newDocuments.filter((d) => d.id !== document.id)
                    )
                  }
                  color="gray"
                />

                <>
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-bold" htmlFor="name">
                      {t('admin.documents.uploadModal.name.label', 'Name')}
                    </label>
                    <Input
                      id="name"
                      color="gray"
                      className="w-[288px]"
                      placeholder={t(
                        'admin.documents.uploadModal.name.placeholder',
                        'User-friendly name'
                      )}
                      value={document?.friendlyName}
                      onChange={(e) =>
                        setNewDocuments(
                          newDocuments.map((newDocument) => {
                            if (document.id === newDocument.id) {
                              return {
                                ...newDocument,
                                friendlyName: e.target.value,
                              };
                            }
                            return newDocument;
                          })
                        )
                      }
                      error={Boolean(
                        validationError[document.id]?.friendlyName
                      )}
                      errorMessage={validationError[document.id]?.friendlyName}
                    />
                  </div>
                  <div>
                    <SelectMenu
                      color="gray"
                      options={typeOptions}
                      selected={typeOptions.find(
                        (option) => option.id === document?.type
                      )}
                      setSelected={(
                        newSelected: SelectMenuOption<ClaimFileType>
                      ) =>
                        setNewDocuments(
                          newDocuments.map((newDocument) => {
                            if (document.id === newDocument.id) {
                              return {
                                ...newDocument,
                                type: newSelected.id,
                              };
                            }
                            return newDocument;
                          })
                        )
                      }
                      placeholder={t(
                        'admin.documents.uploadModal.type.placeholder',
                        'Document type'
                      )}
                      label={t(
                        'admin.documents.uploadModal.type.label',
                        'Type'
                      )}
                      horizontal
                      className="w-[288px]"
                      error={Boolean(validationError[document.id]?.type)}
                      errorMessage={validationError[document.id]?.type}
                    />
                  </div>
                  {isDocumentTypeFeatherOrProvider && (
                    <>
                      <div>
                        <SelectMenu
                          color="gray"
                          options={categoryOptions}
                          selected={categoryOptions.find(
                            (option) => option.id === document?.category
                          )}
                          setSelected={(
                            newSelected: SelectMenuOption<ClaimDocumentCategory>
                          ) =>
                            setNewDocuments(
                              newDocuments.map((newDocument) => {
                                if (document.id === newDocument.id) {
                                  return {
                                    ...newDocument,
                                    category: newSelected.id,
                                  };
                                }
                                return newDocument;
                              })
                            )
                          }
                          placeholder={t(
                            'admin.documents.uploadModal.category.placeholder',
                            'Document category'
                          )}
                          label={t(
                            'admin.documents.uploadModal.category.label',
                            'Category'
                          )}
                          horizontal
                          className="w-[288px]"
                          error={Boolean(
                            validationError[document.id]?.category
                          )}
                          errorMessage={validationError[document.id]?.category}
                        />
                      </div>
                      <div>
                        <SelectMenu
                          color="gray"
                          options={visibilityOptions}
                          selected={getVisibility(document.id)}
                          setSelected={(newSelected: SelectMenuOption[]) =>
                            updateVisibility(newSelected, document.id)
                          }
                          placeholder={t(
                            'admin.documents.uploadModal.visibility.placeholder',
                            'Visibility option(s)'
                          )}
                          label={t(
                            'admin.documents.uploadModal.visibility.label',
                            'Visibility'
                          )}
                          horizontal
                          className="w-[288px]"
                          multiple
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <label className="text-sm font-bold" htmlFor="notes">
                          {t(
                            'admin.documents.uploadModal.notes.label',
                            'Notes'
                          )}
                        </label>
                        <Input
                          id="notes"
                          color="gray"
                          className="w-[288px]"
                          placeholder={t(
                            'admin.documents.uploadModal.notes.placeholder',
                            'Internal notes'
                          )}
                          value={document?.internalNotes}
                          onChange={(e) =>
                            setNewDocuments(
                              newDocuments.map((newDocument) => {
                                if (document.id === newDocument.id) {
                                  return {
                                    ...newDocument,
                                    internalNotes: e.target.value,
                                  };
                                }
                                return newDocument;
                              })
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                  <div className="mt-[20px] w-full border-t border-gray-300" />
                </>
              </>
            );
          })}
          <FileDropzone
            uploadedFile={undefined}
            onFileSelect={(newFile) => newFile && addNewFile(newFile)}
            onFileDelete={() => setNewDocuments([])}
            color="gray"
          />
        </div>
      </Modal>
    </>
  );
};
