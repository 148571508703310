import { GET_BIKE_POLICIES } from 'pages/policies/bike/graphql/policies';
import { GET_DENTAL_POLICIES } from 'pages/policies/dental/graphql/policies';
import { GET_EXPAT_POLICIES } from 'pages/policies/expatHealth/graphql/policies';
import { GET_GENERIC_POLICIES } from 'pages/policies/generic/graphql/policies';
import { GET_HOUSEHOLD_POLICIES } from 'pages/policies/household/graphql/policies';

import { GET_EXPAT_SPAIN_POLICIES } from '../../../policies/expatSpain/graphql/policies';
import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const getPoliciesQuery = (insuranceType: TransactionInsuranceType) => {
  switch (insuranceType) {
    case 'INCOMING':
      return GET_EXPAT_POLICIES;
    case 'BIKE':
      return GET_BIKE_POLICIES;
    case 'DENTAL':
      return GET_DENTAL_POLICIES;
    case 'INCOMING_ES':
      return GET_EXPAT_SPAIN_POLICIES;
    case 'PET_HEALTH':
      return GET_GENERIC_POLICIES;
    case 'HOUSEHOLD':
      return GET_HOUSEHOLD_POLICIES;
  }
};
