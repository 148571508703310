import { SelectMenuOption } from 'components/SelectMenu';
import {
  ClaimAssessmentFileType,
  ClaimAssessmentFileVisibilityTarget,
} from 'models/ClaimAssessment';

export const typeOptions: SelectMenuOption<ClaimAssessmentFileType>[] = [
  {
    id: 'FEATHER_UPLOADS',
    label: 'Feather uploads',
  },
  {
    id: 'PROVIDER_UPLOADS',
    label: 'Provider uploads',
  },
  {
    id: 'CUSTOMER_UPLOADS',
    label: 'Customer uploads',
  },
];

export const updatableTypeOptions: SelectMenuOption<ClaimAssessmentFileType>[] =
  [
    {
      id: 'FEATHER_UPLOADS',
      label: 'Feather uploads',
    },
    {
      id: 'PROVIDER_UPLOADS',
      label: 'Provider uploads',
    },
  ];

export const visibilityOptions: SelectMenuOption<ClaimAssessmentFileVisibilityTarget>[] =
  [
    {
      id: 'CUSTOMER',
      label: 'Customer',
    },
  ];
