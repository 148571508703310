import CompanyDetailsCard from 'components/CompanyDetailsCard';
import { Company } from 'pages/admin/companies';
import { orderCompanyDetailsByVertical } from 'shared/hrCompanyDetails/orderCompanyDetailsByType';

import { CompanyPoliciesCard } from './components/CompanyPoliciesCard';
import * as styles from './styles';

interface OverviewTabProps {
  company: Company;
  metricsTitle: string;
  policiesTitle: string;
}

const OverviewTab = ({
  company,
  metricsTitle,
  policiesTitle,
}: OverviewTabProps) => {
  const updatedCompany = {
    ...company,
    companyDetails: orderCompanyDetailsByVertical(company.companyDetails),
  };

  return (
    <div className={styles.container}>
      <CompanyPoliciesCard company={updatedCompany} cardTitle={policiesTitle} />
      <CompanyDetailsCard company={updatedCompany} cardTitle={metricsTitle} />
    </div>
  );
};

export default OverviewTab;
