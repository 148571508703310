import Modal from 'components/Modal';
import { Company } from 'pages/admin/companies';
import { useEffect, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import { jsonEditorLocale } from 'shared/jsonEditorLocate';
import { CompanyType } from 'shared/mapCompanyType';

interface EditDetailsFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  company: Company;
  loading: boolean;
  editCompany: (variables: {
    id: string;
    name: string;
    sources: string[];
    type: CompanyType;
    companyDetails: string;
  }) => void;
  description?: string;
}

const EditDetailsForm = ({
  open,
  setOpen,
  company,
  loading,
  editCompany,
  description,
}: EditDetailsFormProps) => {
  const { id, name, sources, type, companyDetails } = company;

  const [metrics, setMetrics] = useState<object>(companyDetails);
  const [metricsError, setMetricsError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleSaveMetrics = () => {
    editCompany({
      id,
      name,
      sources,
      type,
      companyDetails: JSON.stringify(metrics),
    });
  };

  const handleMetricsChange = ({
    jsObject,
    error,
  }: {
    jsObject: object;
    error: object | boolean;
  }) => {
    if (error) {
      setMetricsError(true);
      return;
    }

    if (!jsObject) {
      setDisableSubmit(true);
      return;
    }

    setMetrics(jsObject);
    setMetricsError(false);
    setDisableSubmit(false);
  };

  useEffect(() => {
    if (!open) {
      setDisableSubmit(true);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit metrics"
      handleConfirm={handleSaveMetrics}
      confirmButtonLabel="Save"
      textLoading="Saving..."
      disabled={metricsError || disableSubmit}
      loading={loading}
    >
      <div className="max-h-fit flex flex-col">
        <JSONInput
          locale={jsonEditorLocale}
          width="432px"
          placeholder={metrics}
          onChange={handleMetricsChange}
          confirmGood={false}
          colors={{
            default: '#26262E',
            background_warning: '#F5F5FA',
            background: '#F5F5FA',
            keys: '#26262E',
            keys_whiteSpace: '#26262E',
            /* @ts-expect-error: Ignoring because error property was not declared but is needed */
            error: '#F87171',
            colon: '#26262E',
            primitive: '#6160A2',
            string: '#6160A2',
            number: '#6160A2',
          }}
          style={{
            labels: { color: '#F5F5FA' },
            container: {
              borderRadius: '8px',
              maxHeight: 'fit-content',
              height: '500px',
            },
            warningBox: { backgroundColor: '#F5F5FA' },
            errorMessage: { color: '#F87171' },
            outerBox: { maxHeight: 'fit-content', height: 'auto' },
          }}
        />
        {description && (
          <p className="mt-4 text-sm text-gray-600">{description}</p>
        )}
      </div>
    </Modal>
  );
};

export default EditDetailsForm;
