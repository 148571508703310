import { gql } from '@apollo/client';

export const UPDATE_PUBLIC_PROVIDER_POLICY = gql`
  mutation UpdatePublicHealthPolicy(
    $id: String!
    $kvnr: String!
    $svnr: String!
    $startDate: String!
    $providerBranch: String!
  ) {
    updateProviderPublicHealthPolicy(
      id: $id
      kvnr: $kvnr
      svnr: $svnr
      startDate: $startDate
      providerBranch: $providerBranch
    ) {
      id
      createdAt
      startDate
      providerBranch
      user {
        id
        firstName
        lastName
        dateOfBirth
        svnr
      }
      kvnr
      publicStatus
      studentChecklist {
        completed
      }
      occupation
      source
    }
  }
`;

export const UPDATE_POLICY_STATUS = gql`
  mutation UpdatePublicHealthPolicyStatus(
    $id: String!
    $status: String!
    $documents: [PolicyDocumentUpload!]!
    $category: String
    $description: String
    $isCustomerInformed: Boolean
    $startDate: String
    $issueIds: [String]
    $newIssueStatus: String
  ) {
    updateProviderPublicHealthPolicyStatus(
      id: $id
      status: $status
      startDate: $startDate
    ) {
      id
      publicStatus
      startDate
    }
    updateMultipleProviderPublicHealthIssues(
      userPolicyId: $id
      issueIds: $issueIds
      newStatus: $newIssueStatus
    ) {
      id
      status
    }
    createProviderPublicHealthIssue(
      userPolicyId: $id
      category: $category
      description: $description
      isCustomerInformed: $isCustomerInformed
    ) {
      id
      createdAt
      description
      category
      isCustomerInformed
      status
    }
    uploadProviderPublicHealthPolicyFile(policyId: $id, documents: $documents) {
      id
    }
  }
`;

export const UPLOAD_PROVIDER_POLICY_FILE = gql`
  mutation UploadProviderPublicHealthPolicyFile(
    $policyId: String!
    $documents: [PolicyDocumentUpload!]!
  ) {
    uploadProviderPublicHealthPolicyFile(
      policyId: $policyId
      documents: $documents
    ) {
      id
      name
      url
    }
  }
`;

export const UPLOAD_PROVIDER_POLICY_DOCUMENT = gql`
  mutation UploadProviderPublicHealthPolicyFile(
    $id: String!
    $documents: [PolicyDocumentUpload!]!
  ) {
    uploadProviderPublicHealthPolicyFile(policyId: $id, documents: $documents) {
      id
      name
      url
    }
  }
`;

export const EDIT_PROVIDER_POLICY_FILE = gql`
  mutation EditProviderPublicHealthPolicyDocument(
    $category: String!
    $friendlyName: String!
    $id: String!
    $isVisibleToCustomer: Boolean
  ) {
    editProviderPublicHealthPolicyDocument(
      category: $category
      friendlyName: $friendlyName
      id: $id
      isVisibleToCustomer: $isVisibleToCustomer
    ) {
      id
      name
      category
      isVisibleToCustomer
    }
  }
`;

export const UPDATE_PROVIDER_ISSUE_STATUS = gql`
  mutation UpdateProviderPublicHealthIssue(
    $userPolicyId: String!
    $issueId: String!
    $newStatus: String!
    $comment: String
    $documentId: String
  ) {
    updateProviderPublicHealthIssue(
      userPolicyId: $userPolicyId
      issueId: $issueId
      newStatus: $newStatus
      comment: $comment
      documentId: $documentId
    ) {
      id
      status
    }
  }
`;

export const CREATE_ISSUE = gql`
  mutation CreateProviderPublicHealthIssue(
    $userPolicyId: String!
    $category: String!
    $description: String!
    $isCustomerInformed: Boolean
  ) {
    createProviderPublicHealthIssue(
      userPolicyId: $userPolicyId
      category: $category
      description: $description
      isCustomerInformed: $isCustomerInformed
    ) {
      id
      createdAt
      description
      category
      isCustomerInformed
      status
    }
  }
`;

export const UPDATE_COMMISSION_ENTITLEMENT = gql`
  mutation UpdateCommissionEntitlementStatus(
    $policyId: String!
    $newStatus: String!
  ) {
    changeCommissionEntitlementStatus(
      policyId: $policyId
      newStatus: $newStatus
    ) {
      id
      commissionEntitlement
      commissionEntitlementAcceptedAt
    }
  }
`;
