import {
  DocumentNode,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useDebounce } from 'hooks/useDebounce';
import { FormEvent, useEffect, useState } from 'react';
import { ClaimAssessmentStatus } from 'shared/claimAssessments/models';
import { claimAssessmentStatusNameMapper } from 'shared/claimAssessments/statusNameMapper';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../../../../components/Button';
import ComboBox, {
  ComboBoxOption,
} from '../../../../../components/ComboBox/ComboBox';
import FileDropzone from '../../../../../components/FileDropzone';
import FileErrorCard from '../../../../../components/FileErrorCard';
import { FormInputLabel } from '../../../../../components/FormInputLabel';
import Input from '../../../../../components/Input';
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal';
import SelectMenu, {
  SelectMenuOption,
} from '../../../../../components/SelectMenu';
import { SubmittableClaimAssessment } from '../../../modules/createClaimAssessment/models';
import { ClaimAssessmentCreationData } from '../../models';
import * as styles from './styles';
import { retrievePolicySuggestion } from './utils';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  claimAssessmentCreation: ClaimAssessmentCreationData;
  createClaimAssessmentMutation: DocumentNode;
  refetchQueries: DocumentNode[];
  fetchPoliciesQuery: DocumentNode;
}

export const CreateClaimAssessmentModal = ({
  isOpen,
  setIsOpen,
  claimAssessmentCreation: { createClaimAssessmentSubmissionSchema },
  createClaimAssessmentMutation,
  refetchQueries,
  fetchPoliciesQuery,
}: Props) => {
  const [policySearchQuery, setPolicySearchQuery] = useState('');
  const [errors, setErrors] = useState<Partial<SubmittableClaimAssessment>>({});
  const [answers, setAnswers] = useState<Partial<SubmittableClaimAssessment>>({
    status: 'CUSTOMER_CONTACTED',
  });
  const [submissionDisabled, setSubmissionDisabled] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    setAnswers({ status: 'CUSTOMER_CONTACTED' });
  };

  const policyDebouncedValue = useDebounce(policySearchQuery, 1_000);

  const alertBannersState = useReactiveVar(alertBanners);

  const { loading: policiesLoading, data: policiesData } = useQuery(
    fetchPoliciesQuery,
    {
      variables: {
        insuranceTypes: ['PRIVATE_HEALTH'],
        searchString: policyDebouncedValue,
        offset: 0,
        limit: 20,
        sortOrder: '',
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const policies =
    (
      policiesData as {
        allPolicies: Array<{
          id: string;
          policyNumber: string;
          user: { id: string; lastName: string; firstName: string } | null;
        }>;
      }
    )?.allPolicies ?? null;

  const [createClaimAssessment, { loading: createClaimAssessmentLoading }] =
    useMutation(createClaimAssessmentMutation, {
      refetchQueries,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        setIsOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: 'New APV was successfully created.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        setIsOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    });

  const onReset = () => {
    setAnswers({ status: 'CUSTOMER_CONTACTED' });
    setErrors({});
    setSubmissionDisabled(false);
    setIsOpen(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});

    const validation = createClaimAssessmentSubmissionSchema.safeParse({
      ...answers,
    });

    if (!validation.success) {
      setErrors({
        ...retrieveZodErrorMessages(validation.error),
      });
      setSubmissionDisabled(true);
      return;
    }

    const userId = policies?.find(
      ({ id }: { id: string }) => id === answers.userPolicyId
    )?.user?.id;
    if (userId) {
      await createClaimAssessment({
        variables: {
          ...answers,
          userId,
        },
      });
    }

    closeModal();
  };

  useEffect(() => {
    if (!isOpen) {
      setAnswers({ status: 'CUSTOMER_CONTACTED' });
      setErrors({});
      setSubmissionDisabled(false);
    }
  }, [isOpen]);

  const statusOptions = Object.keys(claimAssessmentStatusNameMapper).map(
    (status) => ({
      id: status as ClaimAssessmentStatus,
      label: claimAssessmentStatusNameMapper[status as ClaimAssessmentStatus],
    })
  );

  return (
    <Modal
      title="New APV"
      open={isOpen}
      setOpen={setIsOpen}
      hideActions={true}
      disabled={submissionDisabled}
      scrollable={true}
    >
      <form onSubmit={onSubmit}>
        <>
          {/* User Policy */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="User policy" optional={false} />
            {policiesLoading && (
              <Loader className="'animate-spin',w-[16px],h-[16px]',ml-[4px]" />
            )}
            <div className="mt-[8px]">
              <ComboBox
                className={styles.input}
                selectedOption={undefined}
                setSelectedOption={({ id: optionId }: ComboBoxOption) => {
                  setAnswers({ ...answers, userPolicyId: optionId });
                }}
                placeholder="Enter name, email or policy number"
                options={
                  policies
                    ? policies.map(({ id, user, policyNumber }) => ({
                        id,
                        label: retrievePolicySuggestion({
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                          policyNumber,
                        }),
                      }))
                    : []
                }
                color="gray"
                useExternalQuery
                setExternalQuery={(query: string) => {
                  setPolicySearchQuery(query);
                }}
                error={errors.userPolicyId}
                useUnfilteredOptions={true}
                dataTestId="userPolicyId-auto-suggest"
              />
            </div>
          </div>

          {/* Status */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="Status" optional={false} />
            <div className="mt-[8px]">
              <SelectMenu
                selected={statusOptions.find(
                  (option) => option.id === answers.status
                )}
                setSelected={(
                  option: SelectMenuOption<ClaimAssessmentStatus>
                ) => {
                  setAnswers({
                    ...answers,
                    status: option.id as ClaimAssessmentStatus,
                  });
                }}
                placeholder=""
                options={statusOptions}
                color="gray"
                error={Boolean(errors.status)}
                errorMessage={errors.status}
                dataTestId="status"
              />
            </div>
          </div>

          {/* Title */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="Title (user-facing)" optional={false} />
            <div className="mt-[8px]">
              <Input
                color="gray"
                placeholder="Title"
                value={answers?.title ?? ''}
                onChange={(e) => {
                  setAnswers({ ...answers, title: e.target.value });
                }}
                error={!!errors.title}
                errorMessage={errors.title}
              />
            </div>
          </div>

          {/* DoctorName */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="Doctor or clinic’s name" />
            <span className={styles.labelOptional}>(optional)</span>
            <div className="mt-[8px]">
              <Input
                value={answers?.doctorName ?? ''}
                onChange={(e) => {
                  setAnswers({ ...answers, doctorName: e.target.value });
                }}
                color="gray"
                placeholder="Doctor or clinic’s name"
                error={!!errors.doctorName}
                errorMessage={errors.doctorName}
              />
            </div>
          </div>

          {/* DoctorEmail */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="Doctor’s email" />
            <span className={styles.labelOptional}>(optional)</span>
            <div className="mt-[8px]">
              <Input
                value={answers?.doctorEmail ?? ''}
                onChange={(e) => {
                  setAnswers({ ...answers, doctorEmail: e.target.value });
                }}
                color="gray"
                placeholder="Email"
                error={!!errors.doctorEmail}
                errorMessage={errors.doctorEmail}
              />
            </div>
          </div>

          {/* Diagnosis */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="Diagnosis" />
            <span className={styles.labelOptional}>(optional)</span>
            <div className="mt-[8px]">
              <Input
                value={answers?.diagnosis ?? ''}
                onChange={(e) => {
                  setAnswers({ ...answers, diagnosis: e.target.value });
                }}
                color="gray"
                placeholder="Diagnosis"
                error={!!errors.diagnosis}
                errorMessage={errors.diagnosis}
              />
            </div>
          </div>

          {/* Document upload */}
          <div className={styles.inputContainer}>
            <FormInputLabel title="Upload documents" optional />
            <div className="mt-[8px]">
              <div className="mt-[8px]">
                <FileDropzone
                  uploadedFile={answers.uploadDocument}
                  onFileSelect={(file) => {
                    setAnswers({ ...answers, uploadDocument: file });
                  }}
                />
                {errors.uploadDocument && (
                  <FileErrorCard
                    open={!!errors.uploadDocument}
                    title="Upload failed. Please try again."
                    handleClose={() => {}}
                    errorType="ERROR"
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.bottomText}>
            Once confirmed, a new APV will be created, and the customer will be
            able to view it on their account.
          </div>
          <div className="mt-[16px] flex justify-end">
            <Button
              className="w-[80px]"
              buttonType="secondary"
              type="button"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button
              className="ml-[8px] w-[140px]"
              buttonType="primary"
              type="submit"
              loading={createClaimAssessmentLoading}
              disabled={typeof answers?.userPolicyId !== 'string'}
            >
              Create
            </Button>
          </div>
        </>
      </form>
    </Modal>
  );
};
