import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';
import dayjs from 'dayjs';

import * as styles from './styles';

interface Props {
  claimType: string;
  createdAt?: string;
  amount: number | null;
  status?: string;
  badgeColor?: BadgeColor;
  icon?: string;
  link?: string;
  openInNewTab?: boolean;
}

export const ClaimCard = ({
  claimType,
  createdAt,
  amount,
  status,
  icon,
  link,
  badgeColor,
  openInNewTab = false,
}: Props) => {
  const claimCard = (
    <>
      <div className={styles.leftContainer}>
        {icon && (
          <img className={styles.icon} src={icon} alt="insurance icon" />
        )}

        <div className={styles.titleAndDateContainer}>
          <h5 className={styles.title}>{claimType}</h5>
          {createdAt && (
            <div className={styles.createdAtDate}>
              {dayjs(createdAt).format('DD MMM YYYY')}
            </div>
          )}
        </div>
      </div>
      <div className={styles.rightContainer}>
        {amount && (
          <div className={styles.amount}>
            {englishFormattedEuroCurrency(amount)}
          </div>
        )}
        {status && badgeColor && (
          <div className={styles.status}>
            <Badge color={badgeColor} badgeType="full">
              {status}
            </Badge>
          </div>
        )}
      </div>
    </>
  );

  return link ? (
    <a
      className={styles.claimCard}
      href={link}
      target={openInNewTab ? '_blank' : ''}
      rel="noopener noreferrer"
    >
      {claimCard}
    </a>
  ) : (
    <div className={styles.claimCard}>{claimCard}</div>
  );
};
