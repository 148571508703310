import { DocumentNode, useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { Company } from 'pages/admin/companies';
import { useState } from 'react';
import { CompanyType } from 'shared/mapCompanyType';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import EditDetailsForm from './EditDetailsForm';

interface EditDetailsButtonProps {
  company: Company;
  mutation: DocumentNode;
  refetchQueries: DocumentNode[];
  description?: string;
}

const EditDetailsButton = ({
  company,
  mutation,
  refetchQueries,
  description,
}: EditDetailsButtonProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  const onEditComplete = () => {
    setOpenModal(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: t(
        'component.editMetricsButton.saveSuccess',
        'You have successfully saved the changes.'
      ),
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };
  const onEditError = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: t(
        'component.editMetricsButton.error',
        'Something went wrong. Please try again.'
      ),
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };
  const [editCompany, { loading }] = useMutation(mutation, {
    onCompleted: onEditComplete,
    onError: onEditError,
    refetchQueries: [...refetchQueries],
  });

  const handleSaveCompany = (variables: {
    id: string;
    name: string;
    sources: string[];
    type: CompanyType;
    companyDetails: string;
  }) => {
    editCompany({ variables });
  };

  return (
    <div>
      <Button
        buttonType="secondary"
        className="w-[88px]"
        onClick={() => setOpenModal(true)}
        loading={loading}
      >
        Edit
      </Button>
      <EditDetailsForm
        open={openModal}
        setOpen={setOpenModal}
        company={company}
        editCompany={handleSaveCompany}
        loading={loading}
        description={description}
      />
    </div>
  );
};

export default EditDetailsButton;
