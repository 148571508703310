import {
  DocumentSection,
  DocumentsSection,
  MappedDocumentData,
} from 'components/DocumentsSection/DocumentsSection';
import {
  ClaimAssessmentDocuments,
  ClaimAssessmentFileType,
} from 'models/ClaimAssessment';
import { Document } from 'models/Document';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { GET_CLAIM_ASSESSMENT } from '../../../../../modules/details/graphql/queries';
import { EditButton } from './components/EditButton/EditButton';
import { UploadButton } from './components/UploadButton/UploadButton';
import {
  ARCHIVE_CLAIM_ASSESSMENT_DOCUMENT,
  DELETE_CLAIM_ASSESSMENT_DOCUMENT,
  UNARCHIVE_CLAIM_ASSESSMENT_DOCUMENT,
  UPDATE_CLAIM_ASSESSMENT_DOCUMENT,
} from './graphql/mutations';

interface DocumentsSectionProps {
  claimAssessmentId: string;
  userId: string;
  userPolicyId: string;
  documents: ClaimAssessmentDocuments;
}

const mapDocuments =
  (type: ClaimAssessmentFileType) =>
  ({
    url,
    blobName,
    resizedUrl,
    id,
    isVisibleToCustomer,
    internalNotes,
    friendlyName,
    category,
    archivedAt,
  }: Document): MappedDocumentData => {
    const isNotScannedDocument = type !== 'SCANNED_DOCUMENT';
    const isUserPolicyDocument =
      type !== 'CUSTOMER_UPLOADS' && type !== 'SCANNED_DOCUMENT';

    return {
      id,
      fileName: retrieveFileFriendlyName(blobName),
      ...(friendlyName && { friendlyName }),
      blobName,

      url,
      resizedUrl,

      type,
      isVisibleToCustomer,
      ...(internalNotes && { internalNotes }),
      ...(category && { category }),
      ...(archivedAt && { archivedAt }),

      canDelete: isNotScannedDocument,
      canEdit: isUserPolicyDocument,
      canArchive: isUserPolicyDocument,
    };
  };

export const Documents = ({
  claimAssessmentId,
  userId,
  userPolicyId,
  documents,
}: DocumentsSectionProps) => {
  const customerUploads = documents.userUploads.map(
    mapDocuments('CUSTOMER_UPLOADS')
  );
  const scannedDocuments = documents.scannedDocuments.map(
    mapDocuments('SCANNED_DOCUMENT')
  );
  const featherUploads = documents.featherUploads.map(
    mapDocuments('FEATHER_UPLOADS')
  );
  const providerUploads = documents.providerUploads.map(
    mapDocuments('PROVIDER_UPLOADS')
  );
  const archivedDocuments = documents.archivedDocuments.map(
    mapDocuments('ARCHIVED')
  );

  const sections: DocumentSection[] = [
    {
      title: 'Feather uploads',
      documents: featherUploads,
    },
    {
      title: 'Customer uploads',
      documents: customerUploads,
    },
    {
      title: 'Provider uploads',
      documents: providerUploads,
    },
    {
      title: 'Scanned documents',
      documents: scannedDocuments,
    },
    {
      title: 'Archived',
      documents: archivedDocuments,
      defaultOpen: false,
    },
  ];

  return (
    <DocumentsSection
      sections={sections}
      resourceId={claimAssessmentId}
      getResourceQuery={GET_CLAIM_ASSESSMENT}
      uploadButton={
        <UploadButton
          claimAssessmentId={claimAssessmentId}
          userPolicyId={userPolicyId}
          userId={userId}
          refetchQuery={GET_CLAIM_ASSESSMENT}
        />
      }
      editButton={EditButton}
      deleteMutation={DELETE_CLAIM_ASSESSMENT_DOCUMENT}
      editMutation={UPDATE_CLAIM_ASSESSMENT_DOCUMENT}
      archiveMutation={ARCHIVE_CLAIM_ASSESSMENT_DOCUMENT}
      restoreMutation={UNARCHIVE_CLAIM_ASSESSMENT_DOCUMENT}
    />
  );
};
