import { InsuranceType } from 'shared/insurances/types';

export const textByInsuranceMapper: Record<InsuranceType, string> = {
  BASIS_PENSION: '',
  CAR: '',
  COMPANY_PENSION: '',
  DOG_LIABILITY_ES: 'Dog liability policy',
  GENERIC: '',
  JOB: '',
  LIFE: '',
  PENSION: '',
  PUBLIC_HEALTH: '',
  TRAVEL_FR: 'Travel policy',
  PRIVATE_HEALTH: 'Private health policy',
  INCOMING: 'Expat health policy',
  INCOMING_EU: 'Expat health EU policy',
  INCOMING_LT: 'Expat health long-term policy',
  DENTAL: 'Dental policy',
  LIABILITY: 'Liability policy',
  HOUSEHOLD: 'Household policy',
  TRAVEL: 'Travel policy',
  PET_HEALTH: 'Pet health policy',
  DISABILITY: 'Disability policy',
  COMPANY_HEALTH: 'Company health policy',
  COMPANY_LIFE: 'Company life policy',
  BIKE: 'Bike policy',
  LEGAL: 'Legal policy',
  DOG_LIABILITY: 'Dog liability policy',
  INCOMING_ES: 'Expat health policy',
  BIKE_ES: 'Bike ES policy',
};
