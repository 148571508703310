import { TFunction } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';

import { PolicyTemplateData } from '../../template/models/PolicyTemplateData';
import {
  getDbHRInsuranceType,
  retrieveHRPoliciesDetailsPath,
} from '../../template/utils';
import { PriceDetails } from './components/PriceDetails';
import { getPrivateHealthHrStatus } from './data/mapPrivateHealthHrStatus';
import { getTableFiltersConfig } from './data/tableFilters';
import {
  GET_HR_PRIVATE_HEALTH_POLICIES,
  GET_HR_PRIVATE_HEALTH_POLICY,
} from './graphql/policies';
import { HrPrivateHealthPolicy } from './privateHealthHr.models';

const insurancePath = 'private-health';
const insuranceType = getDbHRInsuranceType(insurancePath);

export const getPrivateHealthPoliciesHRTemplateData = (
  t: TFunction,
  company?: string
): PolicyTemplateData<
  {
    hrPrivateHealthPolicies: HrPrivateHealthPolicy[];
  },
  { hrPrivateHealthPolicy: HrPrivateHealthPolicy }
> => ({
  insuranceType,
  navigation: {
    current: `policies-${insurancePath}`,
  },
  queries: {
    allPolicies: GET_HR_PRIVATE_HEALTH_POLICIES,
    policy: GET_HR_PRIVATE_HEALTH_POLICY,
  },
  mutations: {},
  copy: {
    table: {
      title: t('admin.hr.privateHealth.title', 'Private health policies'),
      searchPlaceholder: t(
        'admin.hr.privateHealth.searchPlaceholder',
        'Search by name or email'
      ),
    },
    details: {
      pageTitle: 'Private health policy - Feather Admin Panel',
      title: t('admin.hr.privateHealth.table.title', 'Private health'),
    },
  },
  features: {
    languageSelector: true,
    overviewTable: {
      numberOfRowsPerPage: 12,
      rowConfig: {
        getResourceLink: (data: HrPrivateHealthPolicy) =>
          retrieveHRPoliciesDetailsPath({
            insuranceType: 'private-health',
            id: data.id,
            company: company ?? '',
            country: 'de',
          }),
        cells: [
          {
            type: 'TEXT',
            textColor: 'dark',
            props: {
              getText: (data: HrPrivateHealthPolicy) =>
                data.user?.firstName ?? '',
            },
          },
          {
            type: 'TEXT',
            textColor: 'dark',
            props: {
              getText: (data: HrPrivateHealthPolicy) =>
                data.user?.lastName ?? '',
            },
          },
          {
            type: 'STATUS',
            props: {
              getStatus: (data: HrPrivateHealthPolicy) => {
                return data.archivedAt
                  ? {
                      text: {
                        id: 'ARCHIVED',
                        text: 'Archived',
                      },
                      color: 'gray',
                    }
                  : getPrivateHealthHrStatus(data.publicStatus, t);
              },
            },
            textColor: 'dark',
          },
          {
            type: 'DATE',
            textColor: 'dark',
            props: {
              getDate: (data: HrPrivateHealthPolicy) => data.createdAt ?? '',
            },
          },
          {
            type: 'DATE',
            textColor: 'dark',
            props: {
              getDate: (data: HrPrivateHealthPolicy) =>
                data.user?.dateOfBirth ?? '',
            },
          },
          {
            type: 'TEXT',
            textColor: 'dark',
            props: {
              getText: (data: HrPrivateHealthPolicy) => data.providerId ?? '',
            },
          },
          {
            type: 'DATE',
            textColor: 'dark',
            props: {
              getDate: (data: HrPrivateHealthPolicy) => data.startDate ?? '',
            },
          },
          {
            type: 'FILE',
            textColor: 'dark',
            props: {
              getFileUrl: (data: HrPrivateHealthPolicy) => {
                if (!data.hrLetterForEmployerDocument) return '';

                return data.hrLetterForEmployerDocument[0]?.url || '';
              },
            },
          },
        ],
      },
      queryVariables: { company },
      filtersConfig: getTableFiltersConfig(t),
      headersConfig: [
        {
          id: 'firstName',
          label: t(
            'admin.hr.privateHealth.table.header.firstName.label',
            'First name'
          ),
          component: SortableColumnHeader,
        },
        {
          id: 'lastName',
          label: t(
            'admin.hr.privateHealth.table.header.lastName.label',
            'Last name'
          ),
          component: SortableColumnHeader,
        },
        {
          id: 'status',
          label: t(
            'admin.hr.privateHealth.table.header.status.label',
            'Status'
          ),
          component: SortableColumnHeader,
        },
        {
          id: 'application-sent-at',
          label: t(
            'admin.hr.privateHealth.table.header.applicationSentAt.label',
            'Submitted on'
          ),
          component: ColumnHeader,
        },
        {
          id: 'date-of-birth',
          label: t(
            'admin.hr.privateHealth.table.header.dateOfBirth.label',
            'Date of birth'
          ),
          component: ColumnHeader,
        },
        {
          id: 'provider',
          label: t(
            'admin.hr.privateHealth.table.header.provider.label',
            'Provider'
          ),
          component: ColumnHeader,
        },
        {
          id: 'startDate',
          label: t(
            'admin.hr.privateHealth.table.header.startDate.label',
            'Start date'
          ),
          component: SortableColumnHeader,
        },
        {
          id: 'letter-for-employer',
          label: t(
            'admin.hr.privateHealth.table.header.letterForEmployer.label',
            'Letter for employer'
          ),
          component: ColumnHeader,
        },
      ],
      defaultEmptyStateMessage: {
        title: t(
          'admin.hr.privateHealth.table.emptyStateMessage.title',
          'There are no policies yet'
        ),
        description: (
          <span className="block max-w-[424px] text-center">
            {t(
              'admin.hr.privateHealth.table.emptyStateMessage.description',
              'Your employees can easily sign up for private health insurance through Feather.'
            )}
            <span className="mt-4 flex justify-center">
              <a
                href="https://feather-insurance.com/health-insurance/private"
                className="rounded-[6px] bg-indigo-100 px-7 py-2 text-sm font-bold text-indigo-500 hover:bg-indigo-200"
              >
                {t(
                  'admin.hr.privateHealth.table.emptyStateMessage.link',
                  'Learn more'
                )}
              </a>
            </span>
          </span>
        ),
      },
    },
    details: {
      insuredPersonName: (data?: {
        hrPrivateHealthPolicy: HrPrivateHealthPolicy;
      }) =>
        `${data?.hrPrivateHealthPolicy?.user?.firstName} ${data?.hrPrivateHealthPolicy?.user?.lastName}`,
      overviewTab: {
        getCards: (data?: { hrPrivateHealthPolicy: HrPrivateHealthPolicy }) => [
          {
            editable: false,
            cards: [
              {
                title: t(
                  'admin.hr.privateHealth.overview.employeeInformation.title',
                  'Employee information'
                ),
                rows: [
                  {
                    id: 'firstName',
                    title: t(
                      'admin.hr.privateHealth.overview.firstName.label',
                      'First name'
                    ),
                    data: data?.hrPrivateHealthPolicy?.user?.firstName ?? '',
                    component: TextInformation,
                    type: 'TEXT',
                  },
                  {
                    id: 'lastName',
                    title: t(
                      'admin.hr.privateHealth.overview.lastName.label',
                      'Last name'
                    ),
                    data: data?.hrPrivateHealthPolicy?.user?.lastName ?? '',
                    component: TextInformation,
                    type: 'TEXT',
                  },
                  {
                    id: 'dateOfBirth',
                    title: t(
                      'admin.hr.privateHealth.overview.dateOfBirth.label',
                      'Date of birth'
                    ),
                    data: data?.hrPrivateHealthPolicy?.user?.dateOfBirth ?? '',
                    component: DateInformation,
                    type: 'DATE',
                  },
                ],
              },
            ],
          },
          {
            editable: false,
            cards: [
              {
                title: t(
                  'admin.hr.privateHealth.overview.policyInformation.title',
                  'Policy information'
                ),
                rows: [
                  {
                    id: 'provider',
                    title: t(
                      'admin.hr.privateHealth.overview.provider.label',
                      'Provider'
                    ),
                    data: data?.hrPrivateHealthPolicy?.providerId ?? '',
                    component: TextInformation,
                    type: 'TEXT',
                  },
                  {
                    id: 'status',
                    title: t(
                      'admin.hr.privateHealth.overview.status.label',
                      'Status'
                    ),
                    data: data?.hrPrivateHealthPolicy?.publicStatus ?? '',
                    statusMapping: (status) =>
                      getPrivateHealthHrStatus(status, t),
                    component: StatusInformation,
                    type: 'STATUS',
                  },
                  {
                    id: 'startDate',
                    title: t(
                      'admin.hr.privateHealth.overview.startDate.label',
                      'Start date'
                    ),
                    data: data?.hrPrivateHealthPolicy?.startDate ?? '',
                    component: DateInformation,
                    type: 'DATE',
                  },
                  {
                    id: 'employerContribution',
                    type: 'TEXT',
                    title: t(
                      'admin.hr.privateHealth.overview.employerContribution.label',
                      'Employer contribution'
                    ),
                    data: englishFormattedEuroCurrency(
                      data?.hrPrivateHealthPolicy?.currentTariffInfo
                        ?.employerContributions ?? 0
                    ),
                    component: TextInformation,
                  },
                  {
                    id: 'employeeContribution',
                    type: 'TEXT_WITH_DETAILS',
                    title: t(
                      'admin.hr.privateHealth.overview.employeeContribution.label',
                      'Employee contribution'
                    ),
                    data: englishFormattedEuroCurrency(
                      data?.hrPrivateHealthPolicy?.currentTariffInfo
                        ?.ownContributions ?? 0
                    ),
                    detailsLabel: t(
                      'admin.hr.privateHealth.overview.priceDetails.button.label',
                      'See details'
                    ),
                    component: TextWithDetailsInformation,
                    detailsTitle: t(
                      'admin.hr.privateHealth.overview.priceDtails.modal.title',
                      'Price details'
                    ),
                    renderModalDetails: () => (
                      <PriceDetails
                        tariffInfo={
                          data?.hrPrivateHealthPolicy.currentTariffInfo
                        }
                        occupation={
                          data?.hrPrivateHealthPolicy.insuredPerson
                            ?.occupation ?? 'OTHER'
                        }
                      />
                    ),
                    isModalScrollable: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    documents: {
      uploadOptions: [],
      getDocumentSections: (data) => [
        {
          title: 'Feather uploads',
          documents: data?.hrPrivateHealthPolicy.hrLetterForEmployerDocument
            ? data.hrPrivateHealthPolicy.hrLetterForEmployerDocument.map(
                (doc) => ({
                  id: doc.id,
                  fileName: t(
                    'admin.hr.privateHealth.table.header.letterForEmployer.label',
                    'Letter for employer'
                  ),
                  url: doc.url,
                })
              )
            : [],
        },
      ],
    },
  },
});
