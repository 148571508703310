import { retrieveClaimsDetailsPath } from 'pages/claims/template/utils/utils';
import { Country } from 'shared/countries/models';
import { InsuranceType } from 'shared/insurances/types';

export const getClaimDetailsRouteMapper = (
  region?: Country
): Record<InsuranceType, (claimId: string) => string | null> => ({
  BIKE: (claimId: string) => retrieveClaimsDetailsPath('bike', claimId),

  DENTAL: (claimId: string) => retrieveClaimsDetailsPath('dental', claimId),

  INCOMING: (claimId: string) =>
    retrieveClaimsDetailsPath('expat-health', claimId),

  HOUSEHOLD: (claimId: string) =>
    retrieveClaimsDetailsPath('household', claimId),

  LEGAL: (claimId: string) => retrieveClaimsDetailsPath('legal', claimId),

  LIABILITY: (claimId: string) =>
    retrieveClaimsDetailsPath('liability', claimId),

  PRIVATE_HEALTH: (claimId: string) =>
    retrieveClaimsDetailsPath('private-health', claimId),

  TRAVEL: (claimId: string) => retrieveClaimsDetailsPath('travel', claimId),

  PET_HEALTH: (claimId: string) =>
    retrieveClaimsDetailsPath('pet-health', claimId),

  COMPANY_HEALTH: (claimId: string) =>
    retrieveClaimsDetailsPath('company-health', claimId),

  COMPANY_LIFE: (claimId: string) =>
    retrieveClaimsDetailsPath('company-life', claimId),

  DISABILITY: (claimId: string) =>
    retrieveClaimsDetailsPath('disability', claimId),

  DOG_LIABILITY: (claimId: string) =>
    retrieveClaimsDetailsPath('dog-liability', claimId),

  // Verticals below do not have claims
  PENSION: () => null,
  BASIS_PENSION: () => null,
  CAR: () => null,
  JOB: () => null,
  GENERIC: () => null,
  PUBLIC_HEALTH: () => null,
  LIFE: () => null,
  INCOMING_ES: (claimId: string) =>
    retrieveClaimsDetailsPath('expat-health-es', claimId, 'es'),
  TRAVEL_FR: () => null,
  INCOMING_EU: (claimId: string) =>
    retrieveClaimsDetailsPath('expat-health-eu', claimId, region),
  INCOMING_LT: (claimId: string) =>
    retrieveClaimsDetailsPath('expat-health-long-term', claimId, region),
  COMPANY_PENSION: () => null,
  DOG_LIABILITY_ES: () => null,
  BIKE_ES: () => null,
});
