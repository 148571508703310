import classNames from 'classnames';

export const claimCard = classNames(
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[20px]',
  'flex',
  'justify-between',
  'content-center',
  'hover:bg-gray-50',
  'transition',
  'duration-100',
  'ease-in'
);

export const leftContainer = classNames('flex', 'items-center', 'flex-1');

export const icon = classNames('w-[48px]', 'h-[48px]');

export const titleAndDateContainer = classNames(
  'ml-[12px]',
  'flex',
  'flex-col',
  'justify-center'
);

export const title = classNames('text-sm');

export const createdAtDate = classNames('text-xs', 'text-gray-600', 'mt-[4px]');

export const rightContainer = classNames(
  'flex',
  'items-center',
  'justify-end',
  'gap-4',
  'text-right'
);

export const amount = classNames(
  'text-sm',
  'text-gray-700',
  'font-bold',
  'w-16'
);

export const status = classNames('w-24');
