import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

export const UploadButton = ({
  mutation,
  documentCategories,
  getResourceQuery,
  resourceId,
  documentVisibilityOptions,
  displayInternalNotes = true,
}: {
  mutation: DocumentNode;
  documentCategories: SelectMenuOption[];
  documentVisibilityOptions?: SelectMenuOption[];
  resourceId: string;
  getResourceQuery?: DocumentNode;
  displayInternalNotes?: boolean;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [open, setOpen] = useState(false);
  const [updatedDocuments, setUpdatedDocuments] = useState<
    {
      id: string;
      file: File;
      friendlyName?: string;
      category?: string;
      internalNotes?: string;
      isVisibleToCustomer?: boolean;
      isVisibleToHr?: boolean;
      isVisibleToProvider?: boolean;
    }[]
  >([]);
  const [nameError, setNameError] = useState<Record<string, boolean>>({});
  const [categoryError, setCategoryError] = useState<Record<string, boolean>>(
    {}
  );

  const [uploadDocument, { loading }] = useMutation(mutation, {
    variables: {
      id: resourceId,
    },
    refetchQueries: getResourceQuery ? [getResourceQuery] : [],
    onCompleted: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.multipleDocuments.upload.success.title',
          'Document(s) were uploaded successfully.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.upload.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const resetForm = () => {
    setUpdatedDocuments([]);
  };

  const handleUploadDocument = () => {
    if (!updatedDocuments || updatedDocuments.length === 0) return;
    let blockSubmit = false;

    for (const document of updatedDocuments) {
      if (!document.category) {
        setCategoryError({ ...categoryError, [document.id]: true });
        blockSubmit = true;
      }

      if (document.category !== 'CUSTOMER_UPLOADS') {
        if (!document.friendlyName) {
          setNameError({ ...nameError, [document.id]: true });
          blockSubmit = true;
        }
      }
    }

    if (blockSubmit) return;

    setCategoryError({});
    setNameError({});
    uploadDocument({
      variables: {
        id: resourceId,
        documents: updatedDocuments.map((document) => ({
          file: document.file,
          category: document.category,
          friendlyName: document.friendlyName,
          internalNotes: document.internalNotes,
          isVisibleToCustomer: document.isVisibleToCustomer,
          isVisibleToHr: document.isVisibleToHr,
          isVisibleToProvider: document.isVisibleToProvider,
        })),
      },
    });
  };

  const handleOpenModal = () => {
    resetForm();
    setOpen(true);
  };

  const getVisibility = (id: string) => {
    const selected: SelectMenuOption[] = [];
    const updatedDocument = updatedDocuments?.find(
      (document) => document.id === id
    );

    if (!updatedDocument) return selected;

    if (updatedDocument?.isVisibleToCustomer) {
      const customerOption = documentVisibilityOptions?.find(
        (option) => option.id === 'CUSTOMER'
      );
      if (customerOption) selected.push(customerOption);
    }
    if (updatedDocument?.isVisibleToHr) {
      const hrOption = documentVisibilityOptions?.find(
        (option) => option.id === 'HR'
      );
      if (hrOption) selected.push(hrOption);
    }
    if (updatedDocument?.isVisibleToProvider) {
      const providerOption = documentVisibilityOptions?.find(
        (option) => option.id === 'PROVIDER'
      );
      if (providerOption) selected.push(providerOption);
    }
    return selected;
  };

  const updateVisibility = (newSelected: SelectMenuOption[], id: string) => {
    const customer = newSelected.find((option) => option.id === 'CUSTOMER');
    const hr = newSelected.find((option) => option.id === 'HR');
    const provider = newSelected.find((option) => option.id === 'PROVIDER');

    setUpdatedDocuments(
      updatedDocuments.map((document) => {
        if (document.id === id) {
          return {
            ...document,
            isVisibleToCustomer: !!customer,
            isVisibleToHr: !!hr,
            isVisibleToProvider: !!provider,
          };
        }
        return document;
      })
    );
  };

  const addNewFile = (newFile: File) => {
    const id = uuidv4();
    setUpdatedDocuments([
      ...updatedDocuments,
      {
        id,
        file: newFile,
        isVisibleToCustomer: true,
        isVisibleToHr: true,
        isVisibleToProvider: true,
      },
    ]);
  };

  return (
    <>
      <Button
        type="button"
        className="w-[88px]"
        onClick={() => handleOpenModal()}
        buttonType="secondary"
      >
        {t('admin.documents.action.upload.label', 'Upload')}
      </Button>
      <Modal
        title={t('admin.documents.uploadModal.title', 'Upload document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t(
          'admin.documents.uploadModal.confirm.label',
          'Confirm'
        )}
        loading={loading}
        handleConfirm={handleUploadDocument}
        disabled={!updatedDocuments || updatedDocuments.length === 0}
        scrollable
      >
        <div className="flex flex-col space-y-4">
          {updatedDocuments.map((updatedDocument) => {
            return (
              <>
                <FileDropzone
                  uploadedFile={updatedDocument.file}
                  onFileSelect={() => {}}
                  onFileDelete={() =>
                    setUpdatedDocuments(
                      updatedDocuments.filter(
                        (d) => d.id !== updatedDocument.id
                      )
                    )
                  }
                  color="gray"
                />
                <>
                  {updatedDocument?.category !== 'CUSTOMER_UPLOADS' && (
                    <div className="flex items-center justify-between">
                      <label className="text-sm font-bold" htmlFor="name">
                        {t('admin.documents.uploadModal.name.label', 'Name')}
                      </label>
                      <Input
                        id="name"
                        color="gray"
                        className="w-[288px]"
                        placeholder={t(
                          'admin.documents.uploadModal.name.placeholder',
                          'User-friendly name'
                        )}
                        value={updatedDocument?.friendlyName}
                        onChange={(e) =>
                          setUpdatedDocuments(
                            updatedDocuments.map((document) => {
                              if (document.id === updatedDocument.id) {
                                return {
                                  ...document,
                                  friendlyName: e.target.value,
                                };
                              }
                              return document;
                            })
                          )
                        }
                        error={nameError[updatedDocument.id]}
                        errorMessage={t(
                          'admin.documents.uploadModal.error.label',
                          'This field is required'
                        )}
                      />
                    </div>
                  )}
                  <div>
                    <SelectMenu
                      color="gray"
                      options={documentCategories}
                      selected={documentCategories.find(
                        (option) => option.id === updatedDocument?.category
                      )}
                      setSelected={(newSelected: SelectMenuOption) =>
                        setUpdatedDocuments(
                          updatedDocuments.map((document) => {
                            if (document.id === updatedDocument.id) {
                              return {
                                ...document,
                                category: newSelected.id,
                              };
                            }
                            return document;
                          })
                        )
                      }
                      placeholder={t(
                        'admin.documents.uploadModal.category.placeholder',
                        'Document category'
                      )}
                      label={t(
                        'admin.documents.uploadModal.category.label',
                        'Category'
                      )}
                      horizontal
                      className="w-[288px]"
                      error={categoryError[updatedDocument.id]}
                      errorMessage={t(
                        'admin.documents.uploadModal.error.label',
                        'This field is required'
                      )}
                    />
                  </div>
                  {documentVisibilityOptions &&
                    updatedDocument?.category !== 'CUSTOMER_UPLOADS' && (
                      <div>
                        <SelectMenu
                          color="gray"
                          options={documentVisibilityOptions}
                          selected={getVisibility(updatedDocument.id)}
                          setSelected={(newSelected: SelectMenuOption[]) =>
                            updateVisibility(newSelected, updatedDocument.id)
                          }
                          placeholder={t(
                            'admin.documents.uploadModal.visibility.placeholder',
                            'Visibility option(s)'
                          )}
                          label={t(
                            'admin.documents.uploadModal.visibility.label',
                            'Visibility'
                          )}
                          horizontal
                          className="w-[288px]"
                          multiple
                        />
                      </div>
                    )}
                  {displayInternalNotes &&
                    updatedDocument?.category !== 'CUSTOMER_UPLOADS' && (
                      <div className="flex items-center justify-between">
                        <label className="text-sm font-bold" htmlFor="notes">
                          {t(
                            'admin.documents.uploadModal.notes.label',
                            'Notes'
                          )}
                        </label>
                        <Input
                          id="notes"
                          color="gray"
                          className="w-[288px]"
                          placeholder={t(
                            'admin.documents.uploadModal.notes.placeholder',
                            'Internal notes'
                          )}
                          value={updatedDocument?.internalNotes}
                          onChange={(e) =>
                            setUpdatedDocuments(
                              updatedDocuments.map((document) => {
                                if (document.id === updatedDocument.id) {
                                  return {
                                    ...document,
                                    internalNotes: e.target.value,
                                  };
                                }
                                return document;
                              })
                            )
                          }
                        />
                      </div>
                    )}
                </>
                <div className="mt-[20px] w-full border-t border-gray-300" />
              </>
            );
          })}
          <FileDropzone
            uploadedFile={undefined}
            onFileSelect={(newFile) => newFile && addNewFile(newFile)}
            onFileDelete={() => setUpdatedDocuments([])}
            color="gray"
          />
        </div>
      </Modal>
    </>
  );
};
