import { ApolloError } from '@apollo/client';
import Button from 'components/Button/Button';
import OverviewPage from 'components/OverviewPage/OverviewPage';
import Table from 'components/TableRefactor/Table';
import { EmptyStateMessage } from 'components/TableRefactor/TableEmptyState';
import { useState } from 'react';
import { countryNameMapper } from 'shared/countries/models';

import {
  ClaimAssessmentsTemplateData,
  NUMBER_OF_CLAIM_ASSESSMENTS_PER_PAGE,
} from '../../models';
import { CreateClaimAssessmentModal } from '../CreateClaimAssessmentModal';

interface Props {
  data: ClaimAssessmentsTemplateData;
}

export const ClaimAssessmentsOverview = ({
  data: {
    region,
    queries: { fetchAllClaimAssessments, fetchAllPolicies },
    mutations: { createClaimAssessment },
    overview: { headers, filterConfig, rowConfig, viewConfig },
    claimAssessmentCreation,
  },
}: Props) => {
  const [error, setError] = useState<ApolloError | undefined>();
  const [isNewClaimAssessmentModalOpen, setIsNewClaimAssessmentModalOpen] =
    useState(false);

  const noClaimAssessmentsMessage: EmptyStateMessage = {
    title: 'There are no APVs',
    description: 'It looks like there are no APVs yet.',
  };

  const nVisibleFilters = region === 'eu' ? 3 : 2;
  const tableSubtitle = region ? countryNameMapper[region] : undefined;

  return (
    <OverviewPage
      error={error}
      current="apv"
      title="APVs - Feather Admin Panel"
    >
      {claimAssessmentCreation &&
        createClaimAssessment &&
        (isNewClaimAssessmentModalOpen ? (
          <CreateClaimAssessmentModal
            setIsOpen={setIsNewClaimAssessmentModalOpen}
            isOpen={isNewClaimAssessmentModalOpen}
            claimAssessmentCreation={claimAssessmentCreation}
            createClaimAssessmentMutation={createClaimAssessment}
            fetchPoliciesQuery={fetchAllPolicies}
            refetchQueries={[fetchAllClaimAssessments]}
          />
        ) : (
          <></>
        ))}
      <Table
        nVisibleFilters={nVisibleFilters}
        title="APVs"
        subtitle={tableSubtitle}
        emptyStateLabel={noClaimAssessmentsMessage}
        searchPlaceholder="Search by name, policy number, email and policy ID"
        itemsPerPage={NUMBER_OF_CLAIM_ASSESSMENTS_PER_PAGE}
        query={fetchAllClaimAssessments}
        queryVariables={{
          insuranceTypes: ['PRIVATE_HEALTH'],
          ...(region && region !== 'eu' ? { region: [region] } : {}),
        }}
        headers={headers}
        filterConfig={filterConfig}
        rowConfig={rowConfig}
        viewConfig={viewConfig}
        onError={setError}
        actionButton={
          claimAssessmentCreation &&
          createClaimAssessment && (
            <Button
              className="w-[100px]"
              buttonType="primary"
              onClick={() => setIsNewClaimAssessmentModalOpen(true)}
            >
              New APV
            </Button>
          )
        }
      />
    </OverviewPage>
  );
};
