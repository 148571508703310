import { useQuery } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { BriefcaseIcon, UserIcon } from '@heroicons/react/solid';
import { DetailsPage } from 'components/DetailsPage';
import {
  ExternalLink,
  HeaderInfoText,
  InternalLink,
} from 'components/DetailsPage/models';
import { NotesTab } from 'components/Notes/NotesTab';
import { GET_COMPANIES } from 'graphql/admin/companies/queries';
import { GET_ADMIN_USERS } from 'graphql/admin/users/queries';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { useDebounce } from 'hooks/useDebounce';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { DataAdminUsers, DataCompanies } from 'pages/admin/users/types';
import { useEffect, useState } from 'react';
import { generatePath, RouteComponentProps } from 'react-router';
import { paths } from 'routes/definedPaths';
import { intercomUrl } from 'shared/intercomUrl';
import { mapUserOccupation } from 'shared/mapUserOccupation';

import ActivityTab from '../components/ActivityTab';
import OverviewTab from '../components/OverviewTab';
import PolicyActions from '../components/PolicyActions';
import QuestionnaireTabPublicInternal from '../components/QuestionnaireTabPublicInternal';

type PublicHealthPolicyPageParams = {
  id: string;
};

interface Data {
  publicHealthPolicy: PublicHealthPolicy;
}

const InternalPolicyPage = ({
  match,
}: RouteComponentProps<PublicHealthPolicyPageParams>) => {
  const { loading, error, data } = useQuery<Data>(GET_PUBLIC_POLICY, {
    variables: {
      id: match.params.id,
    },
  });

  const [providerAgentsQuery, setProviderAgentsQuery] = useState('');
  const debouncedProviderAgentsQuery = useDebounce(providerAgentsQuery, 1_000);

  const { data: providerAgentsData } = useQuery<DataAdminUsers>(
    GET_ADMIN_USERS,
    {
      variables: {
        limit: 25,
        offset: 0,
        searchString: `${debouncedProviderAgentsQuery}`,
        sortColumn: '',
        sortOrder: '',
        filterCompany: 'Barmer',
      },
      notifyOnNetworkStatusChange: false,
    }
  );

  const [companiesQuery, setCompaniesQuery] = useState('');
  const debouncedCompaniesQuery = useDebounce(companiesQuery, 1_000);

  const { data: dataCompanies } = useQuery<DataCompanies>(GET_COMPANIES, {
    variables: {
      limit: 25,
      offset: 0,
      searchString: debouncedCompaniesQuery,
      sortColumn: '',
      sortOrder: '',
      filterType: 'any',
    },
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    setCompaniesQuery(data?.publicHealthPolicy.company?.name ?? '');
    setProviderAgentsQuery(data?.publicHealthPolicy.providerAgent?.email ?? '');
  }, [data]);

  const tabs = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Questionnaire',
      href: '#questionnaire',
      withDot: false,
    },
    {
      name: 'Activity',
      href: '#activity',
      withDot: Boolean(data?.publicHealthPolicy.hasInternalActivityDot),
    },
    {
      name: 'Notes',
      href: '#notes',
      withDot: false,
    },
  ];
  const adminUsersList = providerAgentsData?.adminUsers.map((adminUser) => {
    return {
      id: adminUser.id,
      label: adminUser.email,
    };
  });

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        policy: data?.publicHealthPolicy,
        providerAgents: adminUsersList ?? [],
        setProviderAgentsQuery,
        companies: dataCompanies?.companies,
        setCompaniesQuery,
      },
    },
    {
      id: 'questionnaire',
      component: QuestionnaireTabPublicInternal,
      props: {
        policy: data?.publicHealthPolicy,
      },
    },
    {
      id: 'activity',
      component: ActivityTab,
      props: {
        policyId: data?.publicHealthPolicy.id,
        activity: data?.publicHealthPolicy.activity,
      },
    },
    {
      id: 'notes',
      component: NotesTab,
      props: {
        type: 'POLICY',
        entityId: data?.publicHealthPolicy.id,
        refetchQueries: [GET_PUBLIC_POLICY],
      },
    },
  ];

  const internalLinks: InternalLink[] = [
    {
      icon: UserIcon,
      title: `Account: ${data?.publicHealthPolicy.user.firstName} ${data?.publicHealthPolicy.user.lastName} `,
      href: generatePath(paths.customers.details.path, {
        id: data?.publicHealthPolicy.user.id ?? ' ',
      }),
    },
  ];

  const externalLinks: ExternalLink[] = [
    {
      title: 'Intercom',
      href: generatePath<string>(intercomUrl, {
        intercomId: process.env.REACT_APP_INTERCOM_EU_ID ?? ' ',
        userId: data?.publicHealthPolicy.user.intercomEUId ?? ' ',
      }),
    },
  ];

  const headerInfos: HeaderInfoText[] = [
    {
      icon: <BriefcaseIcon />,
      title: mapUserOccupation(
        data?.publicHealthPolicy.occupation ?? '',
        data?.publicHealthPolicy.questionnaire?.answers?.salary,
        data?.publicHealthPolicy.questionnaire?.answers?.otherSituation
      ),
      ...(data?.publicHealthPolicy.occupation === 'UNIVERSITY_STUDENT' && {
        check: data.publicHealthPolicy.studentChecklist?.completed
          ? 'check'
          : 'cross',
      }),
    },
    {
      icon: <ClipboardListIcon />,
      title: 'eGK',
      check: data?.publicHealthPolicy.healthCard?.completed ? 'check' : 'cross',
    },
  ];

  const name =
    data?.publicHealthPolicy.insuredPerson.firstName &&
    data?.publicHealthPolicy.insuredPerson.lastName
      ? capitalizeName({
          firstName: data.publicHealthPolicy.insuredPerson.firstName,
          lastName: data.publicHealthPolicy.insuredPerson.lastName,
        }) ?? 'No name'
      : data?.publicHealthPolicy.user.firstName &&
        data?.publicHealthPolicy.user.lastName
      ? capitalizeName({
          firstName: data.publicHealthPolicy.user.firstName,
          lastName: data.publicHealthPolicy.user.lastName,
        }) ?? 'No name'
      : 'No name';

  return (
    <DetailsPage
      windowTitle="Policy - Feather Admin Panel"
      pageTitle={name}
      backLink={{
        text: 'Back',
      }}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={loading || !data}
      error={error}
      current="policies-public"
      headerActions={<PolicyActions policy={data?.publicHealthPolicy} />}
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      headerInfoTexts={headerInfos}
    />
  );
};

export default InternalPolicyPage;
