import { InformationSection } from 'components/InformationCard';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';

import { UPDATE_CLAIM_ASSESSMENT } from '../../../../../modules/details/graphql/mutations';
import { GET_CLAIM_ASSESSMENT } from '../../../../../modules/details/graphql/queries';

interface Props {
  claimAssessmentId: string;
  claimAssessmentInfoData: InformationSection[];
}

export const ClaimAssessmentInfo = ({
  claimAssessmentInfoData,
  claimAssessmentId,
}: Props) => {
  const claimAssessmentInfoRows = claimAssessmentInfoData.find(
    ({ id }) => id === 'claimAssessmentInfo'
  )?.rows;

  if (!claimAssessmentInfoRows) {
    throw new Error('[Claim info] Rows are not found');
  }

  const editableRows = claimAssessmentInfoRows.reduce((acc, currentValue) => {
    const editableValue =
      currentValue.data !== undefined ? currentValue.data : undefined;

    return {
      ...acc,
      [currentValue.id]: currentValue.multipleOptions
        ? [...currentValue.data.split(',')]
        : editableValue,
    };
  }, {});

  return (
    <EditableInformationCard
      sections={claimAssessmentInfoData}
      editableData={editableRows}
      mutation={UPDATE_CLAIM_ASSESSMENT}
      resourceId={claimAssessmentId}
      refetchQueries={[GET_CLAIM_ASSESSMENT]}
      extraVariables={{ claimAssessmentId }}
      successMessage="You have successfully saved the changes"
    />
  );
};
