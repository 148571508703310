import { capitalizeName } from '@getpopsure/public-utility';

export const retrievePolicySuggestion = ({
  firstName,
  lastName,
  policyNumber,
}: {
  firstName?: string;
  lastName?: string;
  policyNumber?: string;
}): string =>
  firstName && lastName
    ? String(
        `${capitalizeName({
          firstName,
          lastName,
        })}${policyNumber ? `, ${policyNumber}` : ''}`
      )
    : 'No name';
