import classNames from 'classnames';

export const inputContainer = classNames('mt-[16px]');

export const label = classNames('block', 'flex', 'items-center');

export const labelTitle = classNames('text-sm', 'font-bold', 'text-gray-900');

export const labelOptional = classNames('text-sm', 'text-gray-500', 'ml-[8px]');

export const input = classNames('mt-[8px]');

export const textAreaInput = classNames('max-h-[120px]', 'min-h-[60px]');

export const dateInput = classNames('!w-[432px]');

export const error = classNames('text-sm', 'text-red-400', 'mt-[16px]');

export const loader = classNames(
  'animate-spin',
  'w-[16px]',
  'h-[16px]',
  'ml-[4px]'
);

export const uploadError = classNames(
  'rounded-[6px]',
  'bg-red-100',
  'p-4',
  'mt-[8px]'
);
export const uploadErrorContent = classNames('flex');
export const iconWrapper = classNames('flex-shrink-0');
export const icon = classNames('h-5', 'w-5', 'text-red-400');
export const textWrapper = classNames('ml-3');
export const text = classNames('text-sm', 'font-medium', 'text-red-900');
export const buttonWrapper = classNames(
  'ml-auto',
  'pl-3',
  '-mx-1.5',
  '-my-1.5'
);
export const bottomText = classNames('text-sm', 'text-gray-600', 'mt-[24px]');
