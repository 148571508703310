import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import LinkInformation from 'components/InformationCard/LinkInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { EDIT_COMPANY } from 'graphql/admin/companies/mutations';
import { Company } from 'pages/admin/companies';
import {
  formatCompanyName,
  retrieveHRPoliciesOverviewPath,
} from 'pages/policies/template/utils';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { companyTypeOptions } from 'shared/mapCompanyType';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import * as styles from './styles';

interface OverviewTabProps {
  company: Company;
}

const OverviewTab = ({ company }: OverviewTabProps) => {
  const sources = company.sources.reduce(
    (previousValue, currentValue, index) => {
      if (index === 0) {
        return `${currentValue}`;
      }
      return `${previousValue}, ${currentValue}`;
    },
    ''
  );
  const formattedCompanyName = formatCompanyName(company.name);
  const companyInformation: InformationSection[] = [
    {
      title: 'Company Information',
      rows: [
        {
          id: 'name',
          title: 'Company name',
          data: company.name,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
        },
        {
          id: 'sources',
          title: 'Sources',
          data: sources,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
        },
        {
          id: 'type',
          title: 'Company type',
          data: company.type,
          type: 'DROPDOWN',
          component: DropdownInformation,
          editable: true,
          optionMapping: (option: string) => {
            return (
              companyTypeOptions.find((type) => type.id === option)?.label ?? ''
            );
          },
          dropdownOptions: companyTypeOptions,
          multipleOptions: false,
          placeholder: 'Select company type',
        },

        company.activeUsers > 0
          ? {
              id: 'admin-users',
              title: 'Active admin users',
              data: `${company.activeUsers} users`,
              type: 'LINK',
              component: LinkInformation,
              href: setQueryToUrl(paths.adminUsers.internal.path, [
                { key: 'search', value: company.name },
              ]),
              editable: false,
              externalLink: false,
            }
          : {
              id: 'admin-users',
              title: 'Active admin users',
              data: `${company.activeUsers} users`,
              type: 'TEXT',
              component: TextInformation,
              editable: false,
            },
        {
          id: 'createdAt',
          title: 'Created on',
          data: company.createdAt,
          type: 'DATE',
          component: DateInformation,
          editable: false,
        },
        ...(company.type !== 'HR'
          ? [
              {
                id: 'dashboard',
                title: 'Access to dashboard',
                data: 'Provider view',
                type: 'LINK' as const,
                component: LinkInformation,
                href: `/${formattedCompanyName}
                )}/public-health/policies`,
                editable: false,
                externalLink: true,
              },
            ]
          : []),
        ...(company.type === 'HR'
          ? [
              {
                id: 'dashboard',
                title: 'Access to dashboard',
                data: 'HR dashboard view',
                type: 'LINK' as const,
                component: LinkInformation,
                href: generatePath(paths.hr.dashboard.path, {
                  country: 'de',
                  company: formattedCompanyName,
                }),
                editable: false,
                externalLink: true,
              },
              {
                id: 'dashboard',
                title: 'Access to dashboard',
                data: 'Public health view',
                type: 'LINK' as const,
                component: LinkInformation,
                href: retrieveHRPoliciesOverviewPath(
                  'public-health',
                  'de',
                  formattedCompanyName
                ),

                editable: false,
                externalLink: true,
              },
              {
                id: 'dashboard',
                title: 'Access to dashboard',
                data: 'Company pension view',
                type: 'LINK' as const,
                component: LinkInformation,
                href: retrieveHRPoliciesOverviewPath(
                  'company-pension',
                  'de',
                  formattedCompanyName
                ),

                editable: false,
                externalLink: true,
              },
              {
                id: 'dashboard',
                title: 'Access to dashboard',
                data: 'Company health view',
                type: 'LINK' as const,
                component: LinkInformation,
                href: retrieveHRPoliciesOverviewPath(
                  'company-health',
                  'de',
                  formattedCompanyName
                ),

                editable: false,
                externalLink: true,
              },
              {
                id: 'dashboard',
                title: 'Access to dashboard',
                data: 'Company life view',
                type: 'LINK' as const,
                component: LinkInformation,
                href: retrieveHRPoliciesOverviewPath(
                  'company-life',
                  'de',
                  formattedCompanyName
                ),

                editable: false,
                externalLink: true,
              },
            ]
          : []),
      ],
    },
  ];

  const companyInformationEditableData = {
    name: company.name,
    type: company.type.toLowerCase(),
    sources,
  };

  return (
    <div className={styles.tabWrapper}>
      <EditableInformationCard
        sections={companyInformation}
        editableData={companyInformationEditableData}
        resourceId={company.id}
        mutation={EDIT_COMPANY}
        successMessage="Company info successfully updated"
      />
    </div>
  );
};

export default OverviewTab;
