import { useQuery } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { DetailsPage } from 'components/DetailsPage';
import { TabComponent } from 'components/DetailsPage/models';
import { TabInformation } from 'components/Tabs';
import { GET_COMPANY_BY_NAME } from 'graphql/admin/companies/queries';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { Company } from 'pages/admin/companies';
import { RouteComponentProps } from 'react-router';

import OverviewTab from './components/OverviewTab';
import * as styles from './styles';

interface CompanyPageParams {
  company: string;
}
interface Data {
  companyByName: Company;
}

const HrDashboardPage = ({ match }: RouteComponentProps<CompanyPageParams>) => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery<Data>(GET_COMPANY_BY_NAME, {
    variables: {
      name: match.params.company,
    },
  });

  const { value } = useStateWithLocalStorage('userInformation');
  const userInformation = JSON.parse(value);

  if (
    !userInformation.id ||
    !userInformation.email ||
    !userInformation.language
  ) {
    Sentry.captureMessage(
      `[HR_DASHBOARD]: failed to retrieve user data for company ${data?.companyByName.name}`,
      { level: 'warning', extra: { userInformation } }
    );
  }

  const tallyHiddenFields = {
    companyId: String(data?.companyByName.id),
    companyName: data?.companyByName.name,
    adminUserId: userInformation.id,
    adminEmailAddress: userInformation.email,
    adminLanguage: userInformation.language,
  };

  const objectToQueryParams = (object: Record<string, string | undefined>) => {
    return Object.keys(object)
      .flatMap((key) => {
        const currentValue = object[key];
        return currentValue !== undefined
          ? [`${encodeURIComponent(key)}=${encodeURIComponent(currentValue)}`]
          : [];
      })
      .join('&');
  };

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
  ];

  const tabComponents: TabComponent[] = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        company: data?.companyByName,
        metricsTitle: t(
          'admin.hr.dashboard.overview.metrics.title',
          'Employee participation'
        ),
        policiesTitle: t(
          'admin.hr.dashboard.overview.policies.title',
          'Policies'
        ),
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle={`${data?.companyByName.name} - Feather Admin Panel`}
      pageTitle={
        data?.companyByName.name ??
        t('admin.hr.dashboard.overview.title', 'Overview')
      }
      loading={loading}
      error={error}
      tabs={tabs}
      tabComponents={tabComponents}
      current="overview"
      headerActions={
        <a
          href={`https://forms.feather-insurance.com/hr-task?${objectToQueryParams(
            tallyHiddenFields
          )}`}
          target="_blank"
          rel="noreferrer"
          className={classNames(styles.button)}
        >
          {t('admin.hr.dashboard.overview.button.label', 'Request change')}
        </a>
      }
    />
  );
};

export default HrDashboardPage;
