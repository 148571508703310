import { useTranslation } from '@getpopsure/i18n-react';
import Card from 'components/Card';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import { DocumentNode } from 'graphql';
import { Company } from 'pages/admin/companies';
import { hasDataToDisplay } from 'shared/hrCompanyDetails/hasDataToDisplay';
import { mapCompanyDetailsToInformationCard } from 'shared/hrCompanyDetails/mapCompanyDetailsToInformationCard';
import { camelCaseHrInsuranceTypes } from 'shared/hrCompanyDetails/models';

import EditDetailsButton from './EditDetailsButton';

interface CompanyDetailsCardProps {
  company: Company;
  cardTitle: string;
  mutation?: DocumentNode;
  refetchQueries?: DocumentNode[];
  description?: string;
}

const CompanyDetailsCard = ({
  company,
  cardTitle,
  mutation,
  refetchQueries,
  description,
}: CompanyDetailsCardProps) => {
  const { t } = useTranslation();
  const { companyDetails } = company;
  const isOpsView = !!(mutation && refetchQueries);
  /**
   * In the HR View, the status field (if present) is displayed in a separate component
   */
  const keysToHide = isOpsView ? [] : ['status'];

  const companyMetrics: InformationSection = {
    title: cardTitle,
    boldTitle: false,
    rows: mapCompanyDetailsToInformationCard(
      companyDetails,
      camelCaseHrInsuranceTypes,
      keysToHide
    ),
  };

  const editButton = isOpsView ? (
    <div className="flex space-x-2">
      <EditDetailsButton
        company={company}
        mutation={mutation}
        refetchQueries={refetchQueries}
        description={description}
      />
    </div>
  ) : null;

  if (!hasDataToDisplay(companyDetails, keysToHide)) {
    return (
      <Card title={cardTitle} boldTitle={false} actionButton={editButton}>
        <p className="border-t border-t-gray-200 py-[30px] text-center text-sm text-gray-600">
          {t(
            'component.companyDetailsCard.noData.paragraph',
            'No data to display yet.'
          )}
        </p>
      </Card>
    );
  }

  return (
    <InformationCard
      sections={[companyMetrics]}
      additionalActions={editButton}
    />
  );
};

export default CompanyDetailsCard;
