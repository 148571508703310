import { ApolloError } from '@apollo/client';
import Button from 'components/Button';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { User } from 'models/User';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';

import { CreateCustomerModal } from './components/CreateCustomerModal';
import { GET_CUSTOMERS } from './graphql/queries';

const NUMBER_OF_CUSTOMERS_PER_PAGE = 12;

const CustomersPage = () => {
  const [error, setError] = useState<ApolloError | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const largeHeader = {
    width: 'w-[240px]',
    minWidth: 'min-w-[240px]',
    component: SortableColumnHeader,
  };

  const mediumHeader = {
    width: 'w-[140px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  };

  const headers: HeaderConfig[] = [
    {
      id: 'first-name',
      label: 'First name',
      ...largeHeader,
    },
    {
      id: 'last-name',
      label: 'Last name',
      ...largeHeader,
    },
    {
      id: 'email',
      label: 'Email',
      ...largeHeader,
    },
    {
      id: 'date-of-birth',
      label: 'Date of birth',
      ...mediumHeader,
    },
    {
      id: 'created',
      label: 'Created on',
      ...mediumHeader,
    },
    {
      id: 'is-delinquent',
      label: 'Delinquency',
      width: 'w-[64px]',
      minWidth: 'min-w-[64px]',
      component: ColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    getResourceLink: (data: User) =>
      generatePath(paths.customers.details.path, { id: data.id }),
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: User) => data.firstName ?? '' },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: User) => data.lastName ?? '' },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: { getText: (data: User) => data.email ?? '' },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: { getDate: (data: User) => data.dateOfBirth ?? '' },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: { getDate: (data: User) => data.created ?? '' },
      },
      {
        type: 'CHECK',
        textColor: 'light',
        props: {
          getCheck: (data: User) => ({
            check: data.isDelinquent ? 'error' : 'empty',
            badgeCaption: data.isDelinquent ? 'is delinquent' : '',
          }),
        },
      },
    ],
  };

  return (
    <OverviewPage
      current="customers"
      title="Customers - Feather Admin Panel"
      error={error}
    >
      <CreateCustomerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />

      <Table
        title="Customers"
        itemsPerPage={NUMBER_OF_CUSTOMERS_PER_PAGE}
        emptyStateLabel={{
          title: 'There are no customers yet.',
          description: 'It looks like there are no customers yet.',
        }}
        searchPlaceholder="Search by name, email, stripe token, or user id"
        query={GET_CUSTOMERS}
        headers={headers}
        rowConfig={rowConfig}
        filterConfig={[]}
        onError={setError}
        actionButton={
          <Button
            className="w-[138px]"
            buttonType="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Create customer
          </Button>
        }
      />
    </OverviewPage>
  );
};

export default CustomersPage;
