import { Status } from 'models/statusColor';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';

import { BadgeColor } from '../../../../components/Badge/Badge';

export const statusMapping = (status: string, color: BadgeColor): Status => ({
  text: {
    text: status,
    id: status,
  },
  color,
});

export const retrieveClaimAssessmentsOverviewPath = (
  country: Country = 'de'
): string =>
  generatePath(paths.claimAssessments.internal.path, {
    country,
  });

export const retrieveClaimAssessmentsDetailsPath = (
  claimAssessmentId: string,
  country: Country = 'de'
): string =>
  generatePath(paths.claimAssessments.internal.details.path, {
    id: claimAssessmentId,
    country,
  });
