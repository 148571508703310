import EmbeddedSandbox from 'components/ApolloSandbox';
import { Route } from 'models/routes';
import CompaniesPage from 'pages/admin/companies';
import CompanyPage from 'pages/admin/company';
import ReferralCampaignsPage from 'pages/admin/referralCampaigns';
import ReferralCodesPage from 'pages/admin/referralCodes';
import TaskTemplatesPage from 'pages/admin/taskTemplates';
import AdminUserPage from 'pages/admin/user';
import AdminUsersPage from 'pages/admin/users';
import Login from 'pages/auth/login';
import GoogleLoginFailure from 'pages/auth/login/GoogleLoginFailure';
import LoginSuccess from 'pages/auth/login/LoginSuccess';
import Logout from 'pages/auth/logout';
import { ClaimsTemplate } from 'pages/claims/template';
import { ClaimsDetails as ClaimsDetailsTemplate } from 'pages/claims/template/components/ClaimsDetails';
import CustomersPage from 'pages/customers';
import { CustomerDetails } from 'pages/customers/pages/CustomerDetails';
import DataRemovalRequestsPage from 'pages/dataRemovalRequests';
import HrDashboardPage from 'pages/hrDashboard';
import PaymentTransactions from 'pages/payments/claims';
import ExpatHealthPoliciesPage from 'pages/policies/expatHealth/policies';
import ExpatHealthPolicyPage from 'pages/policies/expatHealth/policy';
import InternalPrivateHealthPage from 'pages/policies/privateHealth/policies';
import InternalPrivateHealthPolicyPage from 'pages/policies/privateHealth/policy';
import PublicHealthIssuesPage from 'pages/policies/publicHealth/internal/issues';
import InternalPublicHealthPage from 'pages/policies/publicHealth/internal/policies';
import InternalPolicyPage from 'pages/policies/publicHealth/internal/policy';
import ProviderPublicHealthPage from 'pages/policies/publicHealth/provider/policies';
import PublicHealthPolicyPage from 'pages/policies/publicHealth/provider/policy';
import PoliciesOverviewHRTemplate from 'pages/policies/template/HRTemplate/PoliciesOverviewHRTemplateGuard';
import PolicyDetailsHRTemplate from 'pages/policies/template/HRTemplate/PolicyDetailsHRTemplateGuard';
import PoliciesOverviewTemplate from 'pages/policies/template/PoliciesOverviewTemplateGuard/PoliciesOverviewTemplateGuard';
import PolicyDetailsTemplate from 'pages/policies/template/PolicyDetailsTemplateGuard/PolicyDetailsTemplateGuard';
import QuestionnairesPage from 'pages/questionnaires';
import QuestionnaireDetailsPage from 'pages/questionnaires/details';
import {
  ScannedDocumentDetailsPage,
  ScannedDocumentsListPage,
} from 'pages/scannedDocuments';
import { SDCategoryDetailsPage } from 'pages/scannedDocuments/categories/details/sdCategory.Details';
import { SDCategoriesListPage } from 'pages/scannedDocuments/categories/list/SDCategories.List';
import TkApiRequestsPage from 'pages/tkApiRequests';
import TkApiRequestDetailsPage from 'pages/tkApiRequests/details';

import { ClaimAssessmentsTemplate } from '../pages/claimAssessments/template';
import { ClaimAssessmentDetails } from '../pages/claimAssessments/template/components/details';
import { paths } from './definedPaths';

// TODO: [KONG] Refactor routes to use shared variables
const routes: Route[] = [
  {
    component: Login,
    path: paths.auth.path,
  },
  {
    component: Logout,
    path: paths.auth.logOut.path,
  },
  {
    component: CustomersPage,
    path: paths.customers.path,
  },
  {
    component: CustomerDetails,
    path: paths.customers.details.path,
  },
  {
    component: InternalPublicHealthPage,
    path: paths.policies.publicHealth.internal.path,
  },
  {
    component: InternalPolicyPage,
    path: paths.policies.publicHealth.internal.details.path,
  },
  {
    component: PublicHealthIssuesPage,
    path: paths.policies.publicHealth.internal.issues.path,
  },
  {
    component: ProviderPublicHealthPage,
    path: paths.policies.publicHealth.provider.path,
  },
  {
    component: PublicHealthPolicyPage,
    path: paths.policies.publicHealth.provider.details.path,
  },
  {
    component: InternalPrivateHealthPage,
    path: paths.policies.privateHealth.internal.path,
  },
  {
    component: InternalPrivateHealthPolicyPage,
    path: paths.policies.privateHealth.internal.details.path,
  },
  {
    component: ExpatHealthPoliciesPage,
    path: paths.policies.expatHealth.internal.path,
  },
  {
    component: ExpatHealthPolicyPage,
    path: paths.policies.expatHealth.internal.details.path,
  },
  {
    component: PoliciesOverviewTemplate,
    path: paths.policies.internal.path,
  },
  {
    component: PolicyDetailsTemplate,
    path: paths.policies.internal.details.path,
  },
  {
    component: PoliciesOverviewHRTemplate,
    path: paths.policies.hr.path,
  },
  {
    component: PolicyDetailsHRTemplate,
    path: paths.policies.hr.details.path,
  },
  {
    component: HrDashboardPage,
    path: paths.hr.dashboard.path,
  },
  // Claims -------------------------------------------------
  // TODO: [KONG] Redefine routing with nested Switch Routing
  {
    component: ClaimsTemplate,
    path: paths.claims.internal.path,
  },
  {
    component: ClaimsDetailsTemplate,
    path: paths.claims.internal.details.path,
  },
  // --------------------------------------------------------
  {
    component: GoogleLoginFailure,
    path: paths.auth.google.loginFail.path,
  },
  {
    component: LoginSuccess,
    path: paths.auth.loginSuccess.path,
  },
  {
    component: CompaniesPage,
    path: paths.companies.internal.path,
  },
  {
    component: CompanyPage,
    path: paths.companies.internal.details.path,
  },
  {
    component: AdminUsersPage,
    path: paths.adminUsers.internal.path,
  },
  {
    component: AdminUserPage,
    path: paths.adminUsers.internal.details.path,
  },
  {
    component: ScannedDocumentsListPage,
    path: paths.scannedDocuments.path,
  },
  {
    component: ScannedDocumentDetailsPage,
    path: paths.scannedDocuments.details.path,
  },
  {
    component: SDCategoriesListPage,
    path: paths.scannedDocuments.categories.path,
  },
  {
    component: SDCategoryDetailsPage,
    path: paths.scannedDocuments.categories.details.path,
  },
  {
    component: PaymentTransactions,
    path: paths.payments.path,
  },
  {
    component: TaskTemplatesPage,
    path: paths.taskTemplates.path,
  },
  {
    component: ReferralCampaignsPage,
    path: paths.referralCampaigns.path,
  },
  {
    component: ReferralCodesPage,
    path: paths.referralCodes.path,
  },
  {
    component: QuestionnairesPage,
    path: paths.questionnaires.path,
  },
  {
    component: QuestionnaireDetailsPage,
    path: paths.questionnaires.details.path,
  },
  {
    component: TkApiRequestsPage,
    path: paths.tkApiRequests.path,
  },
  {
    component: TkApiRequestDetailsPage,
    path: paths.tkApiRequests.details.path,
  },
  {
    component: DataRemovalRequestsPage,
    path: paths.dataRemovalRequests.path,
  },
  {
    component: ClaimAssessmentsTemplate,
    path: paths.claimAssessments.internal.path,
  },
  {
    component: ClaimAssessmentDetails,
    path: paths.claimAssessments.internal.details.path,
  },
  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          component: EmbeddedSandbox,
          path: '/apollo-sandbox',
        },
      ]
    : []),
];

export default routes;
