import { RowConfig } from 'components/TableRefactor/types';
import { claimAssessmentBadgeColorMapper } from 'shared/claimAssessments/badgeColorMapper';
import { claimAssessmentStatusNameMapper } from 'shared/claimAssessments/statusNameMapper';
import { getReadableProviderName } from 'shared/utils/getReadableProviderName';

import { ClaimAssessment } from '../../../../../models/ClaimAssessment';
import { insuranceNameMapper } from '../../../../../shared/insurances/insuranceNameMapper';
import {
  retrieveClaimAssessmentsDetailsPath,
  statusMapping,
} from '../../../template/utils/utils';

export const rowConfig: RowConfig = {
  getResourceLink: (data: ClaimAssessment) =>
    retrieveClaimAssessmentsDetailsPath(data.id),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ClaimAssessment) => data.user.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ClaimAssessment) => data.user.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: (data: ClaimAssessment) =>
          statusMapping(
            data.status ? claimAssessmentStatusNameMapper[data.status] : '',
            data.status ? claimAssessmentBadgeColorMapper[data.status] : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ClaimAssessment) =>
          data.policy?.insuranceType
            ? insuranceNameMapper[data.policy.insuranceType]
            : '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ClaimAssessment) =>
          data.policy?.providerId
            ? getReadableProviderName(data.policy.providerId)
            : '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: ClaimAssessment) => data.createdAt,
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: ClaimAssessment) => data?.doctorName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: ClaimAssessment) => data?.diagnosis ?? '',
      },
    },
  ],
};
