import omitDeep from 'omit-deep-lodash';

import { camelCaseHrInsuranceTypes } from './models';

/**
 * Company details card state is empty when
 * 1. companyDetails is an empty object
 * 2. companyDetails is not grouped by HR insurance type
 * 3. there's no available metric to show for any HR insurance type
 */
export const isValidCompanyDetailsProperty = (
  key: string,
  value: unknown
): value is Record<string, unknown> => {
  return (
    camelCaseHrInsuranceTypes.includes(key) &&
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    Object.keys(value).length > 0
  );
};

export const hasDataToDisplay = (
  companyDetails: object,
  keysToHide: string[]
) =>
  Object.entries(omitDeep(companyDetails, keysToHide)).some(([key, value]) => {
    return isValidCompanyDetailsProperty(key, value);
  });
