import { gql } from '@apollo/client';

export const GET_CLAIM_ASSESSMENTS = gql`
  query GetClaimAssessments(
    $insuranceTypes: [String!]!
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
  ) {
    claimAssessments(
      insuranceTypes: $insuranceTypes
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    ) {
      id
      status
      title
      doctorName
      doctorEmail
      diagnosis
      createdAt
      user {
        firstName
        lastName
      }
      policy {
        insuranceType
        providerId
      }
    }
    totalClaimAssessments(
      insuranceTypes: $insuranceTypes
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    )
  }
`;
