import { ClaimAssessmentStatus } from '../shared/claimAssessments/models';
import { CombinedClaimStatus } from '../shared/claims/models';
import { InsuranceType } from '../shared/insurances/types';
import { Claim } from './Claim';
import { Document } from './Document';
import { Policy } from './policy';

interface User {
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ClaimAssessmentPolicy {
  id: string;
  createdAt: string;
  policyNumber: string;
  insuranceType: InsuranceType;
  providerId: string;
  user: User;
}

export interface ClaimAssessment {
  id: string;
  user: User;
  status: ClaimAssessmentStatus | null;
  createdAt: string;
  policy: ClaimAssessmentPolicy;
  title: string;
  diagnosis: string | null;
  doctorName: string | null;
  doctorEmail: string | null;
  claims: APVClaim[];
  documents: ClaimAssessmentDocuments;
}

export type APVClaim = Pick<
  Claim<Policy, CombinedClaimStatus>,
  'id' | 'claimType' | 'createdAt' | 'amount' | 'status'
>;

export interface ClaimAssessmentDocuments {
  userUploads: Document[];
  scannedDocuments: Document[];
  featherUploads: Document[];
  providerUploads: Document[];
  archivedDocuments: Document[];
}

export const claimAssessmentFileTypes = [
  'FEATHER_UPLOADS',
  'PROVIDER_UPLOADS',
  'CUSTOMER_UPLOADS',
  'SCANNED_DOCUMENT',
  'ARCHIVED',
] as const;
export type ClaimAssessmentFileType = typeof claimAssessmentFileTypes[number];

export const claimAssessmentFileVisibilityTargets = ['CUSTOMER'] as const;
export type ClaimAssessmentFileVisibilityTarget =
  typeof claimAssessmentFileVisibilityTargets[number];
