import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import Card from 'components/Card';
import { Company } from 'pages/admin/companies';
import { useState } from 'react';
import { isValidCompanyDetailsProperty } from 'shared/hrCompanyDetails/hasDataToDisplay';
import { hrInsuranceTypesToCamelCase } from 'shared/hrCompanyDetails/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

import { CompanyPolicyCard } from '../CompanyPolicyCard';
import * as styles from './styles';

interface CompanyPoliciesCardProps {
  company: Company;
  cardTitle: string;
}

export const CompanyPoliciesCard = ({
  company,
  cardTitle,
}: CompanyPoliciesCardProps) => {
  const { t } = useTranslation();

  const [isPreview, setIsPreview] = useState(true);
  const CARD_PREVIEW_AMOUNT = 3;

  const getActivePolicyInsuranceTypes = () => {
    return Object.entries(company.companyDetails).flatMap(([key, value]) => {
      if (
        isValidCompanyDetailsProperty(key, value) &&
        'status' in value &&
        value.status === 'ACTIVE'
      ) {
        return Object.entries(hrInsuranceTypesToCamelCase).flatMap(
          ([insuranceType, mappedValue]) =>
            mappedValue === key ? [insuranceType] : []
        );
      }
      return [];
    });
  };

  const activePolicyInsuranceTypes = getActivePolicyInsuranceTypes();
  const hasActivePolicies = !!(
    activePolicyInsuranceTypes && activePolicyInsuranceTypes.length > 0
  );

  return (
    <Card title={cardTitle} boldTitle={false}>
      {!hasActivePolicies && (
        <p className={classNames(styles.paragraph)}>
          {t(
            'component.companyPoliciesCard.noData.paragraph',
            'No policies created yet.'
          )}
        </p>
      )}
      {activePolicyInsuranceTypes.map((insuranceType, index) => {
        if (isPreview && index > CARD_PREVIEW_AMOUNT - 1) {
          return;
        }
        const policyMapper = policyMappers[insuranceType as InsuranceType];
        if (!policyMapper) {
          return <></>;
        }
        return (
          <CompanyPolicyCard
            key={insuranceType}
            status={t(
              'component.companyPoliciesCard.activeStatus.label',
              'Active'
            )}
            badgeColor="green"
            title={policyMapper.name}
            icon={policyMapper.icon}
          />
        );
      })}
      {isPreview && activePolicyInsuranceTypes.length > CARD_PREVIEW_AMOUNT && (
        <div className={classNames(styles.buttonContainer)}>
          <Button buttonType="transparent" onClick={() => setIsPreview(false)}>
            {t(
              'component.companyPoliciesCard.showMoreButton.label',
              'Show more'
            )}
          </Button>
        </div>
      )}
    </Card>
  );
};
