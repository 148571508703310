import { ClaimAssessmentStatus } from './models';

export const claimAssessmentStatusNameMapper: Record<
  ClaimAssessmentStatus,
  string
> = {
  CUSTOMER_CONTACTED: 'Customer contacted',
  DOCTOR_CONTACTED: 'Doctor contacted',
  RELEASE_SENT: 'Release sent',
  FORM_SENT: 'Form sent',
  CUSTOMER_FOLLOW_UP: 'Customer follow-up',
  DOCTOR_FOLLOW_UP: 'Doctor follow-up',
  PROVIDER_FOLLOW_UP: 'Provider follow-up',
  NEGATIVE: 'Negative',
  POSITIVE: 'Positive',
};
