import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { EditButtonProps } from 'components/DocumentsSection/DocumentsSection';
import AcceptedFile from 'components/FileDropzone/AcceptedFile';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import _ from 'lodash';
import { ClaimFileType } from 'models/Claim';
import { useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { getMimeType } from 'shared/utils/getMimeType';
import { v4 as uuidv4 } from 'uuid';

import { updatableTypeOptions, visibilityOptions } from '../../data';
import { UPDATE_CLAIM_ASSESSMENT_DOCUMENT } from '../../graphql/mutations';
import * as styles from './styles';
import { updateClaimAssessmentDocumentSchema } from './zodSchema';

export const EditButton = ({ document, refetchQuery }: EditButtonProps) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);

  const currentDocumentInfo = {
    friendlyName: document?.friendlyName,
    type: document?.type,
    category: document?.category,
    isVisibleToCustomer: document?.isVisibleToCustomer,
    internalNotes: document?.internalNotes,
  };

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [updatableDocument, setUpdatableDocument] = useState<
    Partial<{
      friendlyName: string;
      type: string;
      category: string;
      isVisibleToCustomer: boolean;
      internalNotes: string;
    }>
  >(currentDocumentInfo);
  const [validationError, setValidationError] = useState<
    Partial<{
      id: string;
      friendlyName: string;
      type: string;
      category: string;
      isVisibleToCustomer: string;
      internalNotes: string;
    }>
  >({});

  const [updateDocument, { loading }] = useMutation(
    UPDATE_CLAIM_ASSESSMENT_DOCUMENT,
    {
      onCompleted: () => {
        setOpen(false);
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: t(
            'admin.documents.edit.success.title',
            'Document was edited successfully.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        setOpen(false);
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: t(
            'admin.documents.edit.error.title',
            'Something went wrong. Please try again.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      ...(refetchQuery && { refetchQueries: [refetchQuery] }),
    }
  );

  const handleSaveDocument = () => {
    setValidationError({});

    if (_.isEqual(currentDocumentInfo, updatableDocument)) {
      // If new answers are same as current document info, close form
      setOpen(false);
      return;
    }

    const variables = {
      id: document.id,
      friendlyName: updatableDocument?.friendlyName,
      category: updatableDocument?.category,
      internalNotes: updatableDocument?.internalNotes,
      isVisibleToCustomer: updatableDocument?.isVisibleToCustomer,
      type: updatableDocument?.type,
    };

    const validation = updateClaimAssessmentDocumentSchema.safeParse({
      ...variables,
    });

    if (!validation.success) {
      setValidationError({
        ...retrieveZodErrorMessages(validation.error),
      });
      return;
    }

    updateDocument({ variables });
  };

  const handleOpenModal = () => {
    resetForm();
    fetchBlob();
    setOpen(true);
  };

  const fetchBlob = async () => {
    if (document?.url) {
      const response = await fetch(document.url, {
        method: 'GET',
        headers: {
          'Content-Type': getMimeType(document.fileName),
        },
      });
      let blob = await response.blob();
      if (!blob.type) {
        blob = new Blob([blob], {
          type: getMimeType(document.fileName),
        });
      }

      setFile(new File([blob], document.fileName));
    }
  };

  const resetForm = () => {
    setUpdatableDocument({
      friendlyName: document?.friendlyName,
      type: document?.type,
      category: document?.category,
      isVisibleToCustomer: document?.isVisibleToCustomer,
      internalNotes: document?.internalNotes,
    });
    setValidationError({});
  };

  const getVisibility = () =>
    visibilityOptions.filter(
      (option) =>
        updatableDocument?.isVisibleToCustomer && option.id === 'CUSTOMER'
    );

  const updateVisibility = (newSelected: SelectMenuOption[]) => {
    const customer = newSelected.find((option) => option.id === 'CUSTOMER');

    setUpdatableDocument({
      ...updatableDocument,
      isVisibleToCustomer: !!customer,
    });
  };

  return (
    <>
      <button
        type="button"
        className={styles.dropdownOption}
        onClick={() => handleOpenModal()}
      >
        {t('admin.documents.action.edit.label', 'Edit')}
      </button>
      <Modal
        title={t('admin.documents.editModal.title', 'Edit document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t('admin.documents.editModal.save.label', 'Save')}
        loading={loading}
        handleConfirm={handleSaveDocument}
        disabled={_.isEqual(currentDocumentInfo, updatableDocument)}
      >
        <div className="flex flex-col space-y-4">
          {file ? (
            <AcceptedFile file={file} download={true} color="gray" />
          ) : (
            <Loader className="animate-spin" />
          )}
          <div className="flex items-center justify-between">
            <label className="text-sm font-bold" htmlFor="name">
              {t('admin.documents.editModal.name.label', 'Name')}
            </label>
            <Input
              id="name"
              color="gray"
              className="w-[288px]"
              placeholder={t(
                'admin.documents.editModal.name.placeholder',
                'User-friendly name'
              )}
              value={updatableDocument.friendlyName}
              onChange={(e) =>
                setUpdatableDocument({
                  ...updatableDocument,
                  friendlyName: e.target.value,
                })
              }
              error={Boolean(validationError?.friendlyName)}
              errorMessage={validationError?.friendlyName}
            />
          </div>
          <div>
            <SelectMenu
              color="gray"
              options={updatableTypeOptions}
              selected={updatableTypeOptions.find(
                (option) => option.id === updatableDocument?.type
              )}
              setSelected={(newSelected: SelectMenuOption<ClaimFileType>) =>
                setUpdatableDocument({
                  ...updatableDocument,
                  type: newSelected.id,
                })
              }
              placeholder={t(
                'admin.documents.uploadModal.type.placeholder',
                'Document type'
              )}
              label={t('admin.documents.uploadModal.type.label', 'Type')}
              horizontal
              className="w-[288px]"
              error={Boolean(validationError?.type)}
              errorMessage={validationError?.type}
            />
          </div>
          <div>
            <SelectMenu
              color="gray"
              options={visibilityOptions}
              selected={getVisibility()}
              setSelected={updateVisibility}
              placeholder={t(
                'admin.documents.editModal.visibility.placeholder',
                'Visibility option(s)'
              )}
              label={t(
                'admin.documents.editModal.visibility.label',
                'Visibility'
              )}
              horizontal
              className="w-[288px]"
              multiple
            />
          </div>
          <div className="flex items-center justify-between">
            <label className="text-sm font-bold" htmlFor="notes">
              {t('admin.documents.editModal.notes.label', 'Notes')}
            </label>
            <Input
              id="notes"
              color="gray"
              className="w-[288px]"
              placeholder={t(
                'admin.documents.editModal.notes.placeholder',
                'Internal notes'
              )}
              value={updatableDocument.internalNotes}
              onChange={(e) =>
                setUpdatableDocument({
                  ...updatableDocument,
                  internalNotes: e.target.value,
                })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
