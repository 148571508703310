import classNames from 'classnames';

export const container = classNames('animate-appear-in');

export const showMoreButton = classNames(
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[20px]',
  'flex',
  'justify-center',
  'items-center',
  'hover:bg-gray-50',
  'transition',
  'duration-100',
  'ease-in'
);

export const feathers = classNames('h-[96px]', 'mb-[32px]');

export const noClaimsContainer = classNames(
  'flex',
  'flex-col',
  'items-center',
  'justify-center',
  'px-[24px]',
  'py-[32px]'
);
export const noClaimsText = classNames(
  'text-base',
  'font-normal',
  'text-gray-900',
  'block'
);

export const noClaimsBottomText = classNames('text-sm', 'text-gray-600');
