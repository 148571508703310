import { ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { ExternalLink } from 'components/DetailsPage/models';
import Link from 'components/Link/Link';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import * as styles from './styles';

export interface TabInformation {
  name: string;
  href: string;
  withDot: boolean;
}

interface TabsProps {
  tabs: TabInformation[];
  children: React.ReactElement[] | React.ReactElement;
  links?: ExternalLink[];
}

const Tabs = ({ tabs, children, links }: TabsProps) => {
  const { hash } = useLocation();
  const [currentTab, setCurrentTab] = useState(
    tabs.find((tab) => tab.href === hash) ?? tabs[0]
  );

  useEffect(() => {
    setCurrentTab(tabs.find((tab) => tab.href === hash) ?? tabs[0]);
    window.scrollTo(0, 0);
  }, [hash]);

  const hasAvailableLinks = links && links.length > 0;

  return (
    <div className={styles.tabsWrapper}>
      <div className={classNames(styles.tabs)}>
        <nav className={styles.nav}>
          <div className={styles.tabsInnerContainer} aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.href === currentTab?.href
                    ? styles.currentTab
                    : styles.disabledTab,
                  styles.tab
                )}
              >
                <span>{tab.name}</span>
                {tab.withDot && <Badge badgeType="dot" color="yellow" />}
              </a>
            ))}
          </div>
          {hasAvailableLinks && (
            <div className={styles.linksContainer}>
              {links.map(({ title, href }, idx) => (
                <div
                  className={classNames(styles.linkInnerContainer, {
                    'ml-[24px]': idx !== 0,
                  })}
                >
                  <Link
                    color="grey"
                    text={title}
                    IconComponent={ExternalLinkIcon}
                    href={href}
                    iconLeft={false}
                    className="space-x-1"
                    target="_blank"
                  />
                </div>
              ))}
            </div>
          )}
        </nav>
      </div>
      {tabs.map((tab, index) => {
        return (
          <div
            key={tab.href}
            className={tab.href === currentTab.href ? '' : styles.hidden}
          >
            {Array.isArray(children) ? children[index] : children}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
