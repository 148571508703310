import { useQuery } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import { ShieldCheckIcon, UserIcon } from '@heroicons/react/solid';
import { DetailsPage } from 'components/DetailsPage';
import {
  ExternalLink,
  HeaderInfoText,
  InternalLink,
} from 'components/DetailsPage/models';
import { retrieveExternalLinks } from 'components/DetailsPage/utils';
import { NotesTab } from 'components/Notes/NotesTab';
import { TabInformation } from 'components/Tabs';
import Page404 from 'pages/errors/404';
import { generatePath, useParams } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import { getIntercomUrl } from 'shared/utils/getIntercomUrl';

import { InsuranceType } from '../../../../../shared/insurances/types';
import { ClaimAssessmentsData } from '../../../index';
import { textByInsuranceMapper } from '../../mappers';
import { retrieveClaimAssessmentInfoRows } from './components/ClaimAssessmentInfo/data';
import { OverviewTab } from './components/OverviewTab';
import { StatusUpdate } from './components/StatusUpdate/StatusUpdate';

export const ClaimAssessmentDetails = () => {
  const { id: claimAssessmentId, country } = useParams<{
    id?: string;
    country: Country;
  }>();

  const {
    queries: { fetchSingleClaimAssessment },
    claimAssessmentDetails,
    statusUpdate: { statusOptions, dataByStatus },
  } = ClaimAssessmentsData;

  const { loading, error, data } = useQuery(fetchSingleClaimAssessment, {
    variables: {
      claimAssessmentId,
    },
  });

  if (!country) {
    return <Page404 />;
  }

  if (!claimAssessmentId) {
    throw new Error(`[APV] No claim assessment id found.`);
  }
  const claimAssessmentData = data?.claimAssessment;

  const policyId = claimAssessmentData?.policy.id;
  const insuranceType = claimAssessmentData?.policy.insuranceType;
  const userId = claimAssessmentData?.user.id;
  const intercomUrl = getIntercomUrl(
    claimAssessmentData?.user.intercomEuUserId ?? undefined
  );

  const insuredPersonName =
    claimAssessmentData?.policy?.insuredPerson?.firstName &&
    claimAssessmentData.policy.insuredPerson.lastName
      ? capitalizeName({
          firstName: claimAssessmentData.policy.insuredPerson.firstName,
          lastName: claimAssessmentData.policy.insuredPerson.lastName,
        })
      : undefined;

  const userAccountName =
    claimAssessmentData?.user.firstName && claimAssessmentData.user.lastName
      ? capitalizeName({
          firstName: claimAssessmentData?.user.firstName,
          lastName: claimAssessmentData?.user.lastName,
        })
      : undefined;

  const headerInfoTexts: HeaderInfoText[] = [];
  const retrievePolicyDetailsPageRoute =
    policyRouteMapper[insuranceType as InsuranceType];
  const policyDetailsPathWithPolicyId = policyId
    ? retrievePolicyDetailsPageRoute(policyId)
    : '';

  const userAccountPathWithUserId = userId
    ? generatePath(paths.customers.details.path, { id: userId })
    : '';

  const internalLinks: InternalLink[] = [
    ...(policyDetailsPathWithPolicyId
      ? [
          {
            title: textByInsuranceMapper[insuranceType as InsuranceType],
            icon: ShieldCheckIcon,
            href: policyDetailsPathWithPolicyId,
          },
        ]
      : []),
    {
      title: `Account: ${userAccountName ?? 'No name'}`,
      icon: UserIcon,
      href: userAccountPathWithUserId,
    },
    ...(claimAssessmentDetails?.internalLinks ?? []),
  ];

  const externalLinks: ExternalLink[] = retrieveExternalLinks([
    { title: 'Intercom', href: intercomUrl },
    ...(claimAssessmentDetails?.externalLinks ?? []),
  ]);

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Notes',
      href: '#notes',
      withDot: false,
    },
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        claimAssessmentInfo: claimAssessmentData,
        retrieveClaimAssessmentInfoRows,
      },
    },
    {
      id: 'notes',
      component: NotesTab,
      props: {
        type: 'CLAIM_ASSESSMENT',
        entityId: claimAssessmentData?.id,
        refetchQueries: [fetchSingleClaimAssessment],
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle="APV - Feather Admin Panel"
      pageTitle="APV"
      backLink={{ text: 'Back' }}
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={loading}
      current="APV"
      pageSubtitle={insuredPersonName || userAccountName || 'No name'}
      headerInfoTexts={headerInfoTexts}
      headerActions={
        <div className="flex items-center justify-between">
          <StatusUpdate
            claimAssessmentId={claimAssessmentId}
            claimAssessmentStatus={claimAssessmentData?.status ?? undefined}
            dataByStatus={dataByStatus}
            statusOptions={statusOptions}
            userPolicyId={policyId}
            createdOn={claimAssessmentData?.createdAt}
          />
        </div>
      }
      error={error}
    />
  );
};
