import { useQuery } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import {
  DocumentTextIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { ActivityLog } from 'components/ActivityLog/ActivityLog';
import { DetailsPage } from 'components/DetailsPage';
import { ExternalLink, InternalLink } from 'components/DetailsPage/models';
import { retrieveExternalLinks } from 'components/DetailsPage/utils';
import { IssuesTab } from 'components/Issues/IssuesTab';
import { NotesTab } from 'components/Notes/NotesTab';
import { TabInformation } from 'components/Tabs';
import Page404 from 'pages/errors/404';
import { generatePath, useParams } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';
import { policyMappers } from 'shared/insurances';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import { claimIssueCategoryMapper } from 'shared/issues/claims/issueCategoryMapper';
import { claimIssueCategories } from 'shared/issues/claims/models';
import { getIntercomUrl } from 'shared/utils/getIntercomUrl';
import { getStripeUrl } from 'shared/utils/getStripeUrl';
import { shouldDisplayIssueActivityDot } from 'shared/utils/shouldDisplayIssueActivityDot';

import { claimsTemplateDataMapper } from '../../data';
import {
  CREATE_CLAIM_ISSUE,
  RE_OPEN_CLAIM_ISSUE,
  RESOLVE_CLAIM_ISSUE,
} from '../../graphql/issues';
import { textByInsuranceMapper } from '../../mappers';
import { InsurancePathName } from '../../models';
import {
  getInsurancePathByCountry,
  getInsuranceTypeFromPath,
  isInsurancePathName,
} from '../../utils/utils';
import { StatusUpdate } from '../StatusUpdate';
import { SubmitClaim } from '../SubmitClaim';
import { OverviewTab } from './components/OverviewTab';
import {
  hasPolicyDetailsPage,
  retrieveClaimsData,
  retrieveReadableClaimTypes,
} from './utils';

export const ClaimsDetails = () => {
  const {
    id: claimId,
    insurancePathName,
    country,
  } = useParams<{
    id?: string;
    insurancePathName: InsurancePathName;
    country: Country;
  }>();

  const {
    insuranceType,
    queries: { fetchSingleClaim },
    mutations: { updateClaim, updateClaimStatus, submitClaim },
    claimsDetails,
    policyInfo: { retrievePolicyInfoRows },
    claimInfo: {
      retrieveClaimInfoRows,
      retrieveAdditionalClaimInfoRows,
      ClaimInfoConfirmationModal,
      retrieveClaimInfoOldConfirmationValue,
    },
    statusUpdate: { statusOptions, dataByStatus },
  } = claimsTemplateDataMapper()[insurancePathName];

  const {
    loading,
    error,
    data: claimData,
  } = useQuery(fetchSingleClaim, {
    variables: {
      claimId,
      insuranceType,
    },
  });

  if (!isInsurancePathName(insurancePathName)) {
    return <Page404 />;
  }
  if (!getInsurancePathByCountry()[insurancePathName].includes(country)) {
    return <Page404 />;
  }

  if (!claimId) {
    throw new Error(`[${insuranceType} claims] No claim id found.`);
  }

  const fetchedClaimDetails = retrieveClaimsData(insuranceType, claimData);

  const policyId = fetchedClaimDetails?.policy.id;
  const userId = fetchedClaimDetails?.user.id;
  const email = fetchedClaimDetails?.user.email ?? undefined;

  const intercomUrl = getIntercomUrl(
    fetchedClaimDetails?.intercomEuUserId ?? undefined
  );
  const stripeUrl = getStripeUrl(
    fetchedClaimDetails?.stripeCustomerToken ?? undefined
  );

  const insuredPersonName =
    fetchedClaimDetails?.policy?.insuredPerson?.firstName &&
    fetchedClaimDetails.policy.insuredPerson.lastName
      ? capitalizeName({
          firstName: fetchedClaimDetails.policy.insuredPerson.firstName,
          lastName: fetchedClaimDetails.policy.insuredPerson.lastName,
        })
      : undefined;

  const userAccountName =
    fetchedClaimDetails?.user.firstName && fetchedClaimDetails.user.lastName
      ? capitalizeName({
          firstName: fetchedClaimDetails?.user.firstName,
          lastName: fetchedClaimDetails?.user.lastName,
        })
      : undefined;

  const headerInfoTexts = [
    ...(fetchedClaimDetails?.claimType?.length &&
    policyMappers[insuranceType]?.claim?.typeNameMapper[
      fetchedClaimDetails.claimType[0].type
    ]
      ? [
          {
            title: retrieveReadableClaimTypes(
              fetchedClaimDetails.claimType.length,
              policyMappers[insuranceType]?.claim?.typeNameMapper[
                fetchedClaimDetails.claimType[0].type
              ] ?? ''
            ),
            icon: <DocumentTextIcon />,
          },
        ]
      : []),
  ];

  const retrievePolicyDetailsPageRoute = policyRouteMapper[insuranceType];
  const policyDetailsPathWithPolicyId = policyId
    ? retrievePolicyDetailsPageRoute(policyId)
    : '';

  const userAccountPathWithUserId = userId
    ? generatePath(paths.customers.details.path, { id: userId })
    : '';

  const internalLinks: InternalLink[] = [
    ...(policyDetailsPathWithPolicyId
      ? [
          {
            title:
              textByInsuranceMapper[insurancePathName].policyDetailsButtonTitle,
            icon: ShieldCheckIcon,
            href: policyDetailsPathWithPolicyId,
            isTemporaryExternal: !hasPolicyDetailsPage(insuranceType),
          },
        ]
      : []),
    {
      title: `Account: ${userAccountName ?? 'No name'}`,
      icon: UserIcon,
      href: userAccountPathWithUserId,
    },
    ...(claimsDetails?.internalLinks ?? []),
  ];

  const externalLinks: ExternalLink[] = retrieveExternalLinks([
    { title: 'Intercom', href: intercomUrl },
    { title: 'Stripe', href: stripeUrl },
    ...(claimsDetails?.externalLinks ?? []),
  ]);

  const shouldShowActivityDot = shouldDisplayIssueActivityDot(
    fetchedClaimDetails?.issues
  );

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Activity',
      href: '#activity',
      withDot: false,
    },
    {
      name: 'Issues',
      href: '#issues',
      withDot: shouldShowActivityDot,
    },
    {
      name: 'Notes',
      href: '#notes',
      withDot: false,
    },
  ];

  const policyInfoRows = retrievePolicyInfoRows(fetchedClaimDetails?.policy);

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        claimInfo: fetchedClaimDetails,
        policyInfoRows,
        refetchQueries: fetchSingleClaim,
        updateClaimMutation: updateClaim,
        retrieveClaimInfoRows,
        retrieveAdditionalClaimInfoRows,
        ClaimInfoConfirmationModal,
        retrieveClaimInfoOldConfirmationValue,
      },
    },
    {
      id: 'activity',
      component: ActivityLog,
      props: {
        where: [
          {
            table: 'user_claims',
            field: 'id',
            value: fetchedClaimDetails?.id,
          },
          {
            table: 'user_claim_types',
            field: 'user_claim_id',
            value: fetchedClaimDetails?.id,
            returnFields: ['claim_type'],
          },
          {
            table: 'user_claim_submissions',
            field: 'user_claim_id',
            value: fetchedClaimDetails?.id,
            returnFields: ['error_details', 'submission_details'],
          },
          {
            table: 'user',
            field: 'id',
            value: fetchedClaimDetails?.user.id,
          },
          ...['user_claims_user_uploads'].map((table) => ({
            table,
            field: 'user_claim_id',
            value: fetchedClaimDetails?.id,
          })),
          {
            table: 'user_policy_issues',
            field: 'user_claim_id',
            value: fetchedClaimDetails?.id,
            returnFields: ['title', 'category', 'reasonForReopening'],
          },
          ...(fetchedClaimDetails?.issues
            ? fetchedClaimDetails.issues.map(({ id }) => ({
                table: 'tasks',
                field: 'user_policy_issue_id',
                value: id,
                returnFields: ['description'],
              }))
            : []),
        ],
      },
    },
    {
      id: 'issues',
      component: IssuesTab,
      props: {
        issues: fetchedClaimDetails?.issues,
        refetchQueries: [fetchSingleClaim],
        issueCategories: claimIssueCategories,
        issueCategoryMapper: claimIssueCategoryMapper,

        createIssueMutation: CREATE_CLAIM_ISSUE,
        resolveIssueMutation: RESOLVE_CLAIM_ISSUE,
        reOpenIssueMutation: RE_OPEN_CLAIM_ISSUE,

        userId: fetchedClaimDetails?.user.id,
        taskContext: textByInsuranceMapper[insurancePathName].taskContext,
        insuranceType: getInsuranceTypeFromPath(insurancePathName),
      },
    },
    {
      id: 'notes',
      component: NotesTab,
      // TODO: [KONG] Props should be typed
      props: {
        type: 'CLAIM',
        entityId: fetchedClaimDetails?.id,
        refetchQueries: [fetchSingleClaim],
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle={
        textByInsuranceMapper[insurancePathName].windowTitle.detailsPage
      }
      pageTitle="Claim"
      backLink={{ text: 'Back' }}
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={loading}
      current={`claims-${insurancePathName}`}
      pageSubtitle={insuredPersonName || userAccountName || 'No name'}
      headerInfoTexts={headerInfoTexts}
      headerActions={
        <div className="flex items-center justify-between">
          <StatusUpdate
            claimId={claimId}
            refetchQueries={[fetchSingleClaim]}
            claimStatus={fetchedClaimDetails?.status ?? undefined}
            updateStatusMutation={updateClaimStatus}
            dataByStatus={dataByStatus}
            statusOptions={statusOptions}
            userInfo={{ email, policyId, userId }}
            createdOn={fetchedClaimDetails?.createdAt}
          />
          {submitClaim && (
            <SubmitClaim claimId={claimId} mutation={submitClaim} />
          )}
        </div>
      }
      error={error}
    />
  );
};
