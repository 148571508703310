import classNames from 'classnames';

export const label = classNames('text-sm', 'text-gray-900', 'font-bold');
export const toggleLabel = classNames(
  'text-lg',
  'text-gray-900',
  'font-bold',
  'mr-[8px]'
);

export const badge = classNames('mt-[8px]');

export const formContainer = classNames('mt-[24px]');

export const formOptionalLabel = classNames(
  'text-sm',
  'text-gray-500',
  'font-bold'
);

export const bottomText = classNames('text-sm', 'text-gray-600', 'mt-[24px]');

export const formInputContainer = classNames('mt-[8px]');

export const dateInput = classNames('!w-[432px]');

export const error = classNames('mt-[8px]', 'text-xs', 'text-red-400');

export const description = classNames('text-sm', 'text-gray-600');

export const radioButtons = classNames(
  'flex',
  'items-center',
  'space-x-[32px]'
);

export const toggleBody = classNames(
  'w-[44px]',
  'h-[24px]',
  'px-[2px]',
  'rounded-[12px]'
);
export const toggleWrapper = classNames('top-[2px]');
