import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import Button from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import { ClaimCard } from 'components/ClaimCard';
import ComboBox, { ComboBoxOption } from 'components/ComboBox/ComboBox';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import * as styles from 'components/ModalForm/styles';
import { useDebounce } from 'hooks/useDebounce';
import { FormEvent, useState } from 'react';
import { CombinedClaimStatus, CombinedClaimType } from 'shared/claims/models';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { Mappers } from 'shared/insurances';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { LINK_CLAIMS_TO_CLAIM_ASSESSMENT } from '../../../../../modules/details/graphql/mutations';
import {
  GET_CLAIM_ASSESSMENT,
  GET_CLAIMS_BY_POLICY_ID,
} from '../../../../../modules/details/graphql/queries';
import { retrieveClaimSuggestion } from './utils';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userPolicyId: string;
  linkedClaimIds: string[];
  claimAssessmentId: string;
  policyMapper: Mappers | null;
}

const ZLinkClaimsToClaimAssessmentSchema = z.object({
  claimAssessmentId: z.string().min(1, { message: 'This field is required.' }),
  claimIds: z.array(z.string()).optional(),
});
type LinkClaimsToClaimAssessment = z.infer<
  typeof ZLinkClaimsToClaimAssessmentSchema
>;

export const EditLinkedClaimsModal = ({
  isOpen,
  setIsOpen,
  linkedClaimIds,
  userPolicyId,
  claimAssessmentId,
  policyMapper,
}: Props) => {
  const [claimSearchQuery, setClaimSearchQuery] = useState('');
  const [answers, setAnswers] = useState<Partial<LinkClaimsToClaimAssessment>>({
    claimAssessmentId,
    claimIds: linkedClaimIds,
  });
  const [errors, setErrors] = useState<Partial<LinkClaimsToClaimAssessment>>(
    {}
  );
  const [submissionDisabled, setSubmissionDisabled] = useState(false);
  const alertBannersState = useReactiveVar(alertBanners);

  const claimsDebouncedValue = useDebounce(claimSearchQuery, 1_000);
  const { loading: claimsLoading, data: claimsData } = useQuery(
    GET_CLAIMS_BY_POLICY_ID,
    {
      variables: {
        userPolicyId,
        searchString: claimsDebouncedValue,
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const claims =
    (
      claimsData as {
        claimsByPolicyId: Array<{
          id: string;
          amount: number;
          claimNumber?: string;
          createdAt?: string;
          status?: CombinedClaimStatus;
          claimType?: { type: CombinedClaimType; id: string }[];
        }>;
      }
    )?.claimsByPolicyId ?? null;

  const [linkClaimsToClaimAssessment] = useMutation(
    LINK_CLAIMS_TO_CLAIM_ASSESSMENT,
    {
      refetchQueries: [GET_CLAIM_ASSESSMENT],
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        setIsOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: 'Your changes successfully saved.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        setIsOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const onReset = () => {
    setAnswers({ claimAssessmentId, claimIds: linkedClaimIds });
    setErrors({});
    setSubmissionDisabled(false);
    setIsOpen(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});

    const validation = ZLinkClaimsToClaimAssessmentSchema.safeParse(answers);

    if (!validation.success) {
      setErrors(retrieveZodErrorMessages(validation.error));
      setSubmissionDisabled(true);
      return;
    }

    await linkClaimsToClaimAssessment({
      variables: answers,
    });

    setIsOpen(false);
  };

  return (
    <Modal
      title={`Linked Claims (${answers.claimIds?.length || 0})`}
      open={isOpen}
      setOpen={setIsOpen}
      hideActions={true}
      disabled={submissionDisabled}
      scrollable={true}
    >
      <form onSubmit={onSubmit}>
        {/* User Claim */}
        <div className="mt-[24px]">
          {claimsLoading && (
            <Loader className="'animate-spin',w-[16px],h-[16px]',ml-[4px]" />
          )}
          <div className="mt-[8px]">
            <ComboBox
              className={styles.input}
              selectedOption={undefined}
              setSelectedOption={({ id: optionId }: ComboBoxOption) => {
                setAnswers((prevAnswers) => {
                  const updatedClaims = prevAnswers.claimIds || [];
                  if (updatedClaims.includes(optionId)) {
                    return {
                      ...prevAnswers,
                      claimIds: updatedClaims.filter((id) => id !== optionId),
                    };
                  }
                  return {
                    ...prevAnswers,
                    claimIds: [...updatedClaims, optionId],
                  };
                });
              }}
              placeholder="Search by claim number, claim type or amount"
              options={
                claims
                  ? claims.map(
                      ({ id, claimNumber, amount, claimType, status }) => ({
                        id,
                        label: retrieveClaimSuggestion({
                          policyMapper,
                          amount,
                          claimNumber,
                          claimType,
                          status,
                        }),
                      })
                    )
                  : []
              }
              color="gray"
              useExternalQuery
              setExternalQuery={(query: string) => {
                setClaimSearchQuery(query);
              }}
              error={errors.claimIds?.join(',')}
              useUnfilteredOptions={true}
            />
          </div>
        </div>

        {/* Selected Claims */}
        <div className="mt-[16px]">
          {claims && claims.length > 0 ? (
            <>
              {claims.map(
                ({ id, amount, claimNumber, claimType, createdAt, status }) => (
                  <div className="w-full text-sm text-gray-900">
                    <CheckBox
                      checked={answers.claimIds?.includes(id) || false}
                      setChecked={() => {
                        setAnswers((prevAnswers) => {
                          const updatedClaims = prevAnswers.claimIds || [];
                          if (updatedClaims.includes(id)) {
                            return {
                              ...prevAnswers,
                              claimIds: updatedClaims.filter(
                                (claimId) => claimId !== id
                              ),
                            };
                          }
                          return {
                            ...prevAnswers,
                            claimIds: [...updatedClaims, id],
                          };
                        });
                      }}
                      label={
                        claimType && policyMapper ? (
                          <ClaimCard
                            key={id}
                            claimType={
                              claimType
                                .map(
                                  ({ type }) =>
                                    policyMapper.claim?.typeNameMapper[type]
                                )
                                .join(', ') ?? ''
                            }
                            createdAt={createdAt}
                            amount={amount}
                            status={
                              policyMapper.claim?.claimStatusMapper[
                                status as CombinedClaimStatus
                              ]
                            }
                            badgeColor={
                              policyMapper.claim?.badgeColorMapper[
                                status as CombinedClaimStatus
                              ]
                            }
                            openInNewTab={false}
                          />
                        ) : (
                          retrieveClaimSuggestion({
                            policyMapper,
                            amount,
                            claimNumber,
                            claimType,
                            status,
                          })
                        )
                      }
                      small
                      className="w-full"
                      labelClassName="w-full"
                    />
                  </div>
                )
              )}
            </>
          ) : (
            <p className="text-sm text-gray-500">No claims selected yet.</p>
          )}
        </div>

        <div className={styles.bottomText}>
          Once confirmed, customer-facing statuses of all selected claims will
          be updated as “Under assessment” .
        </div>
        <div className="mt-[24px] flex justify-end">
          <Button
            className="w-[80px]"
            buttonType="secondary"
            type="button"
            onClick={onReset}
          >
            Cancel
          </Button>
          <Button
            className="ml-[8px] w-[140px]"
            buttonType="primary"
            type="submit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};
