import Button from 'components/Button';
import Card from 'components/Card';
import { ClaimCard } from 'components/ClaimCard';
import { ClaimAssessment } from 'models/ClaimAssessment';
import React, { useState } from 'react';
import { getClaimDetailsRouteMapper } from 'shared/claims/claimRouteMapper';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

import { retrieveReadableClaimTypes } from '../../../../../../claims/template/components/ClaimsDetails/utils';
import { PoliciesTitle } from '../../../../../../customers/pages/CustomerDetails/components/OverviewTab/components/CustomerPolicies/components/PoliciesTitle';
import { EditLinkedClaimsModal } from '../EditLinkedClaimsModal/EditLinkedClaimsModal';
import * as styles from './styles';

interface Props {
  data: ClaimAssessment;
}

export const LinkedClaimsInfo = ({ data }: Props) => {
  const [
    isLinkClaimsToClaimAssessmentModalOpen,
    setLinkClaimsToClaimAssessmentModalOpen,
  ] = useState(false);

  const hasClaims = data.claims && data.claims.length > 0;
  const claimsCount = data?.claims.length ?? 0;
  const policyMapper =
    policyMappers[data.policy.insuranceType as InsuranceType];
  const [showAll, setShowAll] = useState(false);
  const claimsToShow = showAll ? data.claims : data.claims.slice(0, 3);

  return (
    <div className={styles.container}>
      <Card
        title={<PoliciesTitle title="Linked Claims" count={claimsCount} />}
        boldTitle={false}
        actionButton={
          <>
            <Button
              buttonType="secondary"
              onClick={() => setLinkClaimsToClaimAssessmentModalOpen(true)}
              className="w-[88px]"
            >
              Edit
            </Button>
          </>
        }
      >
        {isLinkClaimsToClaimAssessmentModalOpen ? (
          <EditLinkedClaimsModal
            isOpen={isLinkClaimsToClaimAssessmentModalOpen}
            setIsOpen={setLinkClaimsToClaimAssessmentModalOpen}
            linkedClaimIds={data.claims.map((claim) => claim.id)}
            userPolicyId={data.policy.id}
            claimAssessmentId={data.id}
            policyMapper={policyMapper}
          />
        ) : (
          <></>
        )}

        {!hasClaims && (
          <div className={styles.noClaimsContainer}>
            <img
              src="/static/error-states/feathers.svg"
              alt="feathers"
              className={styles.feathers}
            />
            <p className={styles.noClaimsText}>No linked claims yet.</p>
            <p className={styles.noClaimsBottomText}>
              You can add a new one above
            </p>
          </div>
        )}

        {hasClaims &&
          policyMapper &&
          claimsToShow.map(({ id, claimType, createdAt, amount, status }) => {
            return (
              <ClaimCard
                key={id}
                claimType={
                  claimType
                    ? retrieveReadableClaimTypes(
                        claimType.length,
                        policyMapper.claim?.typeNameMapper[
                          claimType[0]?.type
                        ] ?? ''
                      )
                    : ''
                }
                createdAt={createdAt}
                amount={amount}
                status={
                  status ? policyMapper.claim?.claimStatusMapper[status] : ''
                }
                badgeColor={
                  status ? policyMapper.claim?.badgeColorMapper[status] : 'blue'
                }
                icon={policyMapper.icon}
                openInNewTab={false}
                link={
                  getClaimDetailsRouteMapper()[data.policy.insuranceType](id) ??
                  ''
                }
              />
            );
          })}
        {data.claims.length > 3 && (
          <div className={styles.showMoreButton}>
            <Button
              onClick={(e) => {
                e.preventDefault(); // Sayfanın yenilenmesini engeller
                setShowAll(!showAll);
              }}
              buttonType="white"
            >
              {showAll ? 'Show less' : 'Show more'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
