import classNames from 'classnames';

export const button = classNames(
  'py-[9px]',
  'px-[13px]',
  'rounded-[6px]',
  'text-sm',
  'font-bold',
  'flex',
  'justify-center',
  'bg-indigo-500',
  'text-white',
  'hover:bg-indigo-600',
  'outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:bg-indigo-600',
  'focus:ring-indigo-500'
);
