export const genericInsuranceTypes = [
  'CAR',
  'DISABILITY',
  'PET_HEALTH',
  'TRAVEL',
  'COMPANY_LIFE',
  'COMPANY_HEALTH',
  'PENSION',
  'BASIS_PENSION',
  'TRAVEL_FR',
  'GENERIC',
  'INCOMING_EU',
  'INCOMING_LT',
  'COMPANY_PENSION',
  'DOG_LIABILITY_ES',
  'BIKE_ES',
] as const;

export const insuranceTypes = [
  'PUBLIC_HEALTH',
  'PRIVATE_HEALTH',
  'INCOMING',
  'DENTAL',
  'HOUSEHOLD',
  'LIABILITY',
  'JOB',
  'LIFE',
  'LEGAL',
  'BIKE',
  'DOG_LIABILITY',
  'INCOMING_ES',
  ...genericInsuranceTypes,
] as const;

export type InsuranceType = typeof insuranceTypes[number];
export type GenericInsuranceType = typeof genericInsuranceTypes[number];

export type GenericStatus<T = void> = T extends void
  ? 'PENDING' | 'ACTIVE' | 'CANCELED' | 'DROPPED_OUT'
  : 'PENDING' | 'ACTIVE' | 'CANCELED' | 'DROPPED_OUT' | T;
export type GenericStatusWithApplicationSent<T = void> = T extends void
  ? GenericStatus<'APPLICATION_SENT'>
  : GenericStatus<'APPLICATION_SENT'> | T;

export type ExpatStatus = GenericStatus;
export type LiabilityStatus = GenericStatus;
export type HouseholdStatus = GenericStatus;
export type ExpatSpainStatus = GenericStatus;
export type LifeStatus = GenericStatusWithApplicationSent;
export type LegalStatus = GenericStatusWithApplicationSent;
export type DentalStatus = GenericStatusWithApplicationSent;
export type BikeStatus = GenericStatusWithApplicationSent;
export type DisabilityStatus = GenericStatus<'NOT_ELIGIBLE'>;
export type PublicHealthStatus =
  | 'APPLICATION_RECEIVED'
  | 'UNI_CHECK'
  | 'APPLICATION_SENT'
  | 'PROCESSING'
  | 'COVERED'
  | 'ERR_MISSING_INFO'
  | 'DROPPED_OUT'
  | 'CANCELED'
  | 'CANT_BE_COVERED';

export const privateHealthStatuses = [
  'APPLICATION_RECEIVED',
  'PENDING',
  'APPLICATION_SENT',
  'ACTIVE',
  'ERR_MISSING_INFO',
  'CANCELED',
  'DROPPED_OUT',
  'CANT_BE_COVERED',
] as const;
export type PrivateHealthStatus = typeof privateHealthStatuses[number];

export type DogLiabilityStatus = GenericStatus;
