import { gql } from '@apollo/client';

export const UPLOAD_CLAIM_ASSESSMENT_DOCUMENT = gql`
  mutation UploadClaimAssessmentDocument(
    $claimAssessmentId: String!
    $userId: String
    $file: Upload!
    $type: String!
    $isVisibleToCustomer: Boolean!
    $friendlyName: String
    $internalNotes: String
  ) {
    uploadClaimAssessmentDocument(
      claimAssessmentId: $claimAssessmentId
      userId: $userId
      file: $file
      type: $type
      isVisibleToCustomer: $isVisibleToCustomer
      friendlyName: $friendlyName
      internalNotes: $internalNotes
    ) {
      id
      url
      blobName
    }
  }
`;

export const DELETE_CLAIM_ASSESSMENT_DOCUMENT = gql`
  mutation DeleteClaimAssessmentDocument(
    $resourceId: String!
    $id: String!
    $blobName: String!
    $type: String!
  ) {
    deleteClaimAssessmentDocument(
      claimAssessmentId: $resourceId
      id: $id
      blobName: $blobName
      type: $type
    ) {
      id
      name
      type
      blobName
    }
  }
`;

export const UPDATE_CLAIM_ASSESSMENT_DOCUMENT = gql`
  mutation UpdateClaimAssessmentDocument(
    $id: String!
    $type: String!
    $friendlyName: String
    $isVisibleToCustomer: Boolean!
    $internalNotes: String
  ) {
    updateClaimAssessmentDocument(
      id: $id
      type: $type
      friendlyName: $friendlyName
      isVisibleToCustomer: $isVisibleToCustomer
      internalNotes: $internalNotes
    ) {
      id
      url
    }
  }
`;

export const ARCHIVE_CLAIM_ASSESSMENT_DOCUMENT = gql`
  mutation ArchiveClaimAssessmentDocument($id: String!) {
    archiveClaimAssessmentDocument(id: $id) {
      id
    }
  }
`;

export const UNARCHIVE_CLAIM_ASSESSMENT_DOCUMENT = gql`
  mutation UnarchiveClaimAssessmentDocument($id: String!) {
    unarchiveClaimAssessmentDocument(id: $id) {
      id
    }
  }
`;
