import { DocumentNode } from '@apollo/client';
import { ExternalLink, InternalLink } from 'components/DetailsPage/models';
import { InformationSection } from 'components/InformationCard';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { ClaimAssessmentStatus } from 'shared/claimAssessments/models';
import { Country } from 'shared/countries/models';
import { z, ZodRawShape } from 'zod';

import { SelectButtonOption } from '../../../components/SelectButton';
import { StatusUpdateFormQuestion } from '../../../components/StatusUpdateModal/models';

export const NUMBER_OF_CLAIM_ASSESSMENTS_PER_PAGE = 12;

export interface ClaimAssessmentsTemplateData {
  region?: Country;
  queries: {
    fetchAllClaimAssessments: DocumentNode;
    fetchSingleClaimAssessment: DocumentNode;
    fetchAllPolicies: DocumentNode;
    fetchClaimsByPolicyId: DocumentNode;
  };
  mutations: {
    createClaimAssessment: DocumentNode;
    updateClaimAssessment: DocumentNode;
    updateClaimAssessmentStatus: DocumentNode;
    linkClaimsToClaimAssessment: DocumentNode;
  };
  overview: {
    headers: HeaderConfig[];
    filterConfig: FilterConfig[];
    rowConfig: RowConfig;
    viewConfig?: ViewConfig;
  };
  claimAssessmentCreation: ClaimAssessmentCreationData;
  claimAssessmentDetails?: ClaimAssessmentDetails;
  claimAssessmentInfo?: ClaimAssessmentInfo;
  linkedClaimsInfo?: LinkedClaimsInfo;
  statusUpdate: StatusUpdate;
}

export interface ClaimAssessmentCreationData {
  createClaimAssessmentSubmissionSchema: z.ZodObject<ZodRawShape>;
}

export interface ClaimAssessmentDetails {
  externalLinks?: ExternalLink[];
  internalLinks?: InternalLink[];
}

export interface ClaimAssessmentInfo {
  retrieveClaimAssessmentInfoRows: (
    claimAssessmentInfo: any
  ) => InformationSection[];
}

export interface LinkedClaimsInfo {
  retrieveLinkedClaimsInfoRows: (linkedClaimsInfo: any) => InformationSection[];
}

export interface StatusData {
  footerText?: string;
  form: StatusUpdateFormQuestion[];
}

export interface StatusUpdate {
  statusOptions: SelectButtonOption<ClaimAssessmentStatus>[];
  dataByStatus: (status: ClaimAssessmentStatus) => StatusData;
}
