import { genericPolicyDetailsRoute } from 'pages/policies/generic/utils';
import { retrievePoliciesDetailsPath } from 'pages/policies/template/utils';
import { generatePath } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';

import { InsuranceType } from './types';

export const policyRouteMapper: Record<
  InsuranceType,
  (policyId: string, region?: Country) => string
> = {
  BIKE: (policyId: string) =>
    retrievePoliciesDetailsPath({ insuranceType: 'bike', id: policyId }),
  DENTAL: (policyId: string) =>
    retrievePoliciesDetailsPath({ insuranceType: 'dental', id: policyId }),
  INCOMING: (policyId: string) =>
    generatePath(paths.policies.expatHealth.internal.details.path, {
      id: policyId,
    }),
  HOUSEHOLD: (policyId: string) =>
    retrievePoliciesDetailsPath({ insuranceType: 'household', id: policyId }),
  LEGAL: (policyId: string) =>
    retrievePoliciesDetailsPath({ insuranceType: 'legal', id: policyId }),
  LIABILITY: (policyId: string) =>
    retrievePoliciesDetailsPath({ insuranceType: 'liability', id: policyId }),
  LIFE: (policyId: string) =>
    retrievePoliciesDetailsPath({ insuranceType: 'life', id: policyId }),
  PUBLIC_HEALTH: (policyId: string) =>
    generatePath(paths.policies.publicHealth.internal.details.path, {
      id: policyId,
    }),
  PRIVATE_HEALTH: (policyId: string) =>
    generatePath(paths.policies.privateHealth.internal.details.path, {
      id: policyId,
    }),
  JOB: (policyId: string) =>
    `${process.env.REACT_APP_EXTERNAL_ADMIN_PANEL_URL}/admin/admin.job_policies/details/?id=${policyId}`,
  GENERIC: (policyId: string) => genericPolicyDetailsRoute(policyId, 'generic'),
  TRAVEL: (policyId: string) => genericPolicyDetailsRoute(policyId, 'travel'),
  CAR: (policyId: string) => genericPolicyDetailsRoute(policyId, 'car'),
  PET_HEALTH: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'pet-health'),
  COMPANY_HEALTH: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'company-health'),
  COMPANY_LIFE: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'company-life'),
  DISABILITY: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'disability'),
  PENSION: (policyId: string) => genericPolicyDetailsRoute(policyId, 'pension'),
  BASIS_PENSION: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'basis-pension'),
  DOG_LIABILITY: (policyId: string) =>
    retrievePoliciesDetailsPath({
      insuranceType: 'dog-liability',
      id: policyId,
    }),
  INCOMING_ES: (policyId: string) =>
    retrievePoliciesDetailsPath({
      insuranceType: 'expat-health-es',
      id: policyId,
      country: 'es',
    }),
  TRAVEL_FR: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'travel-fr', 'fr'),
  INCOMING_EU: (policyId: string, region?: Country) =>
    genericPolicyDetailsRoute(policyId, 'expat-health-eu', region ?? 'eu'),
  INCOMING_LT: (policyId: string, region?: Country) =>
    genericPolicyDetailsRoute(
      policyId,
      'expat-health-long-term',
      region ?? 'eu'
    ),
  COMPANY_PENSION: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'company-pension'),
  DOG_LIABILITY_ES: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'dog-liability-es', 'es'),
  BIKE_ES: (policyId: string) =>
    genericPolicyDetailsRoute(policyId, 'bike-es', 'es'),
};
