import { useQuery } from '@apollo/client';
import { DetailsPage } from 'components/DetailsPage';
import { TabComponent } from 'components/DetailsPage/models';
import { TabInformation } from 'components/Tabs';
import { EDIT_COMPANY } from 'graphql/admin/companies/mutations';
import { GET_COMPANY } from 'graphql/admin/companies/queries';
import { RouteComponentProps } from 'react-router';

import { Company } from '../companies';
import MetricsTab from './components/MetricsTab';
import OverviewTab from './components/OverviewTab';

type CompanyPageParams = {
  id: string;
};

interface Data {
  company: Company;
}

const CompanyPage = ({ match }: RouteComponentProps<CompanyPageParams>) => {
  const { loading, error, data } = useQuery<Data>(GET_COMPANY, {
    variables: {
      id: match.params.id,
    },
  });

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    ...(data?.company.type === 'HR'
      ? [
          {
            name: 'Metrics',
            href: '#metrics',
            withDot: false,
          },
        ]
      : []),
  ];

  const tabComponents: TabComponent[] = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        company: data?.company,
      },
    },
    {
      id: 'metrics',
      component: MetricsTab,
      props: {
        company: data?.company,
        cardTitle: 'Employee participation',
        mutation: EDIT_COMPANY,
        refetchQueries: [GET_COMPANY],
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle="Company - Feather Admin Panel"
      pageTitle={data?.company.name ?? ''}
      backLink={{
        text: 'Back',
      }}
      loading={loading}
      tabs={tabs}
      tabComponents={tabComponents}
      current="admin-companies"
      error={error}
    />
  );
};

export default CompanyPage;
