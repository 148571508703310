import { camelCaseHrInsuranceTypes } from './models';

export const orderCompanyDetailsByVertical = (
  companyDetails: Record<string, unknown> = {}
) =>
  camelCaseHrInsuranceTypes.reduce((details, insuranceType) => {
    if (companyDetails && insuranceType in companyDetails) {
      return {
        ...details,
        [insuranceType]: companyDetails[insuranceType],
      };
    }
    return details;
  }, {});
