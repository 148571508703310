export const ClaimAssessmentStatuses = [
  'CUSTOMER_CONTACTED',
  'DOCTOR_CONTACTED',
  'RELEASE_SENT',
  'FORM_SENT',
  'CUSTOMER_FOLLOW_UP',
  'DOCTOR_FOLLOW_UP',
  'PROVIDER_FOLLOW_UP',
  'NEGATIVE',
  'POSITIVE',
] as const;
export type ClaimAssessmentStatus = typeof ClaimAssessmentStatuses[number];
