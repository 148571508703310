import { HRTemplateInsuranceType } from 'pages/policies/template/models/InsuranceTypes';

export const hrInsuranceTypesToCamelCase: Record<
  HRTemplateInsuranceType,
  string
> = {
  PUBLIC_HEALTH: 'publicHealth',
  PRIVATE_HEALTH: 'privateHealth',
  COMPANY_PENSION: 'companyPension',
  COMPANY_HEALTH: 'companyHealth',
  COMPANY_LIFE: 'companyLife',
};

export const camelCaseHrInsuranceTypes = Object.values(
  hrInsuranceTypesToCamelCase
);
