import CompanyDetailsCard from 'components/CompanyDetailsCard';
import { DocumentNode } from 'graphql';
import { Company } from 'pages/admin/companies';
import { orderCompanyDetailsByVertical } from 'shared/hrCompanyDetails/orderCompanyDetailsByType';

interface MetricsTabProps {
  company: Company;
  cardTitle: string;
  mutation: DocumentNode;
  refetchQueries: DocumentNode[];
}

const MetricsTab = ({
  company,
  cardTitle,
  mutation,
  refetchQueries,
}: MetricsTabProps) => {
  const updatedCompany = {
    ...company,
    companyDetails: orderCompanyDetailsByVertical(company.companyDetails),
  };

  return (
    <div className="relative flex w-full flex-col">
      <CompanyDetailsCard
        company={updatedCompany}
        cardTitle={cardTitle}
        refetchQueries={refetchQueries}
        mutation={mutation}
      />
    </div>
  );
};

export default MetricsTab;
