import { gql } from '@apollo/client';

export const GET_NOTES = gql`
  query GetNotes($entityId: String!, $entityType: String!) {
    notes(entityId: $entityId, entityType: $entityType) {
      id
      createdAt
      body
      pinned
      adminUser {
        id
        firstName
        lastName
      }
      updatedAt
      replyNotes {
        id
        createdAt
        body
        adminUser {
          id
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
