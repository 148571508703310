import {
  ClaimAssessmentFileType,
  claimAssessmentFileTypes,
} from 'models/ClaimAssessment';
import { z, ZodObject, ZodRawShape } from 'zod';

const schema = z.object({
  claimAssessmentId: z.string().uuid(),
  file: z.custom<File>(),
  friendlyName: z.string().optional(),
  type: z.enum(claimAssessmentFileTypes),
});

export const getUploadDocumentSchema = (
  type?: ClaimAssessmentFileType
): ZodObject<ZodRawShape> => {
  if (type === 'CUSTOMER_UPLOADS') {
    return schema.extend({
      userId: z.string().uuid(),
    });
  }

  if (type === 'FEATHER_UPLOADS' || type === 'PROVIDER_UPLOADS') {
    return schema.extend({
      friendlyName: z.string(),
      isVisibleToCustomer: z.boolean(),
      internalNote: z.string().optional(),
    });
  }

  return schema;
};
