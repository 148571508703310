import { BadgeColor } from 'components/Badge/Badge';

import { ClaimAssessmentStatus } from './models';

export const claimAssessmentBadgeColorMapper: Record<
  ClaimAssessmentStatus,
  BadgeColor
> = {
  CUSTOMER_CONTACTED: 'red',
  DOCTOR_CONTACTED: 'purple',
  RELEASE_SENT: 'blue',
  FORM_SENT: 'blue',
  CUSTOMER_FOLLOW_UP: 'red',
  DOCTOR_FOLLOW_UP: 'purple',
  PROVIDER_FOLLOW_UP: 'blue',
  NEGATIVE: 'green',
  POSITIVE: 'yellow',
};
