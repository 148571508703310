import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { ClaimFileType } from 'models/Claim';
import { ClaimAssessmentFileType } from 'models/ClaimAssessment';
import { useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { typeOptions, visibilityOptions } from '../../data';
import { UPLOAD_CLAIM_ASSESSMENT_DOCUMENT } from '../../graphql/mutations';
import { getUploadDocumentSchema } from './zodSchema';

interface Props {
  claimAssessmentId: string;
  userId: string;
  userPolicyId: string;
  refetchQuery: DocumentNode;
}

export const UploadButton = ({
  claimAssessmentId,
  userId,
  userPolicyId,
  refetchQuery,
}: Props) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();

  const [newDocument, setNewDocument] = useState<{
    friendlyName?: string;
    internalNotes?: string;
    isVisibleToCustomer?: boolean;
    type?: ClaimAssessmentFileType;
  }>({
    isVisibleToCustomer: true,
  });
  const [validationError, setValidationError] = useState<
    Partial<{
      claimId: string;
      file: string;
      friendlyName: string;
      type: string;
      userId: string;
      userPolicyId: string;
      isVisibleToCustomer: string;
      internalNotes: string;
    }>
  >({});

  const [uploadDocument, { loading }] = useMutation(
    UPLOAD_CLAIM_ASSESSMENT_DOCUMENT,
    {
      refetchQueries: [refetchQuery],
      onCompleted: () => {
        setOpen(false);
        resetForm();
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: 'Document was uploaded successfully.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        setOpen(false);
        resetForm();
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const resetForm = () => {
    setNewDocument({
      isVisibleToCustomer: true,
    });
    setFile(undefined);
  };

  const handleUploadDocument = () => {
    setValidationError({});

    const variables = {
      claimAssessmentId,
      file,
      friendlyName: newDocument?.friendlyName,
      internalNotes: newDocument?.internalNotes,
      isVisibleToCustomer: newDocument?.isVisibleToCustomer,
      userId,
      userPolicyId,
      type: newDocument?.type,
    };

    const validation = getUploadDocumentSchema(newDocument?.type).safeParse({
      ...variables,
    });

    if (!validation.success) {
      setValidationError({
        ...retrieveZodErrorMessages(validation.error),
      });
      return;
    }

    uploadDocument({
      variables,
    });
  };

  const handleOpenModal = () => {
    resetForm();
    setValidationError({});
    setOpen(true);
  };

  const getVisibility = () =>
    visibilityOptions.filter(
      (option) => newDocument?.isVisibleToCustomer && option.id === 'CUSTOMER'
    );

  const updateVisibility = (newSelected: SelectMenuOption[]) => {
    const customer = newSelected.find((option) => option.id === 'CUSTOMER');

    setNewDocument({
      ...newDocument,
      isVisibleToCustomer: !!customer,
    });
  };

  const isDocumentTypeFeatherOrProvider =
    newDocument?.type === 'FEATHER_UPLOADS' ||
    newDocument?.type === 'PROVIDER_UPLOADS';

  return (
    <>
      <Button
        type="button"
        className="w-[88px]"
        onClick={() => handleOpenModal()}
        buttonType="secondary"
      >
        {t('admin.documents.action.upload.label', 'Upload')}
      </Button>
      <Modal
        title={t('admin.documents.uploadModal.title', 'Upload document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t(
          'admin.documents.uploadModal.confirm.label',
          'Confirm'
        )}
        loading={loading}
        handleConfirm={handleUploadDocument}
        disabled={!file}
      >
        <div className="flex flex-col space-y-4">
          <FileDropzone
            uploadedFile={file}
            onFileSelect={setFile}
            onFileDelete={() => setFile(undefined)}
            color="gray"
          />

          {file && (
            <>
              <div className="flex items-center justify-between">
                <label className="text-sm font-bold" htmlFor="name">
                  {t('admin.documents.uploadModal.name.label', 'Name')}
                </label>
                <Input
                  id="name"
                  color="gray"
                  className="w-[288px]"
                  placeholder={t(
                    'admin.documents.uploadModal.name.placeholder',
                    'User-friendly name'
                  )}
                  value={newDocument?.friendlyName}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      friendlyName: e.target.value,
                    })
                  }
                  error={Boolean(validationError?.friendlyName)}
                  errorMessage={validationError?.friendlyName}
                />
              </div>
              <div>
                <SelectMenu
                  color="gray"
                  options={typeOptions}
                  selected={typeOptions.find(
                    (option) => option.id === newDocument?.type
                  )}
                  setSelected={(newSelected: SelectMenuOption<ClaimFileType>) =>
                    setNewDocument({
                      ...newDocument,
                      type: newSelected.id,
                    })
                  }
                  placeholder={t(
                    'admin.documents.uploadModal.type.placeholder',
                    'Document type'
                  )}
                  label={t('admin.documents.uploadModal.type.label', 'Type')}
                  horizontal
                  className="w-[288px]"
                  error={Boolean(validationError?.type)}
                  errorMessage={validationError?.type}
                />
              </div>
              {isDocumentTypeFeatherOrProvider && (
                <>
                  <div>
                    <SelectMenu
                      color="gray"
                      options={visibilityOptions}
                      selected={getVisibility()}
                      setSelected={updateVisibility}
                      placeholder={t(
                        'admin.documents.uploadModal.visibility.placeholder',
                        'Visibility option(s)'
                      )}
                      label={t(
                        'admin.documents.uploadModal.visibility.label',
                        'Visibility'
                      )}
                      horizontal
                      className="w-[288px]"
                      multiple
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-bold" htmlFor="notes">
                      {t('admin.documents.uploadModal.notes.label', 'Notes')}
                    </label>
                    <Input
                      id="notes"
                      color="gray"
                      className="w-[288px]"
                      placeholder={t(
                        'admin.documents.uploadModal.notes.placeholder',
                        'Internal notes'
                      )}
                      value={newDocument?.internalNotes}
                      onChange={(e) =>
                        setNewDocument({
                          ...newDocument,
                          internalNotes: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
