import {
  CombinedClaimStatus,
  CombinedClaimType,
} from '../../../../../../../shared/claims/models';
import { Mappers } from '../../../../../../../shared/insurances';

export const retrieveClaimSuggestion = ({
  policyMapper,
  amount,
  claimNumber,
  claimType,
  status,
}: {
  policyMapper: Mappers | null;
  amount?: number;
  claimNumber?: string;
  claimType?: { type: CombinedClaimType }[];
  status?: CombinedClaimStatus;
}): string =>
  amount && claimType
    ? String(
        `${claimNumber}, ${claimType
          .map((claim) => policyMapper?.claim?.typeNameMapper[claim.type])
          .join(',')} ${amount ? `, €${amount}` : ''}, ${
          status ? policyMapper?.claim?.claimStatusMapper[status] : ''
        }`
      )
    : 'No claim';
