import { InsuranceType } from 'shared/insurances/types';

export type TransactionInsuranceType = Extract<
  InsuranceType,
  'BIKE' | 'INCOMING' | 'DENTAL' | 'INCOMING_ES' | 'PET_HEALTH' | 'HOUSEHOLD'
>;

export const expatTransactionInsuranceTypes: TransactionInsuranceType[] = [
  'INCOMING',
  'INCOMING_ES',
];

export const insuranceTypeFromPath = [
  'expat-health',
  'bike',
  'dental',
  'expat-health-es',
  'pet-health',
  'household',
] as const;
export type InsuranceTypeFromPath = typeof insuranceTypeFromPath[number];

export const InsuranceTypeFromPathMapping: Record<
  InsuranceTypeFromPath,
  TransactionInsuranceType
> = {
  'expat-health': 'INCOMING',
  bike: 'BIKE',
  dental: 'DENTAL',
  'expat-health-es': 'INCOMING_ES',
  'pet-health': 'PET_HEALTH',
  household: 'HOUSEHOLD',
};
