import { gql } from '@apollo/client';

export const CREATE_CLAIM_ASSESSMENT = gql`
  mutation CreateClaimAssessment(
    $userId: String!
    $userPolicyId: String!
    $status: String!
    $title: String!
    $doctorName: String
    $doctorEmail: String
    $diagnosis: String
    $uploadDocument: Upload
  ) {
    createClaimAssessment(
      userId: $userId
      userPolicyId: $userPolicyId
      status: $status
      title: $title
      doctorName: $doctorName
      doctorEmail: $doctorEmail
      diagnosis: $diagnosis
      uploadDocument: $uploadDocument
    ) {
      id
      createdAt
      userPolicyId
      status
      title
      user {
        id
        firstName
        lastName
      }
      policy {
        insuranceType
        providerId
      }
      doctorName
      doctorEmail
      diagnosis
    }
  }
`;
