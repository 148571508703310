import { BikePoliciesData } from 'pages/policies/bike/models/BikePolicies';
import { DentalPoliciesData } from 'pages/policies/dental/models/DentalPolicies';
import { ExpatHealthPoliciesData } from 'pages/policies/expatHealth/policies';
import { GenericPoliciesData } from 'pages/policies/generic/models/GenericPoliciesData';
import { HouseholdPoliciesData } from 'pages/policies/household/models/HouseholdPolicies';

import { ExpatSpainPoliciesData } from '../../../policies/expatSpain/models/ExpatSpainPolicy';
import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const getPoliciesData = (
  insuranceType: TransactionInsuranceType,
  data: unknown
) => {
  switch (insuranceType) {
    case 'BIKE':
      return {
        policies: (data as BikePoliciesData | undefined)?.bikePolicies as {
          id: string;
          policyNumber: string;
          user?: { firstName?: string; lastName?: string };
        }[],
        count: (data as BikePoliciesData | undefined)?.totalBikePolicies,
      };

    case 'DENTAL':
      return {
        policies: (data as DentalPoliciesData | undefined)?.dentalPolicies as {
          id: string;
          policyNumber: string;
          user?: { firstName?: string; lastName?: string };
        }[],
        count: (data as DentalPoliciesData | undefined)?.totalDentalPolicies,
      };

    case 'INCOMING':
      return {
        policies: (data as ExpatHealthPoliciesData | undefined)
          ?.expatPolicies as {
          id: string;
          policyNumber: string;
          user?: { firstName?: string; lastName?: string };
        }[],
        count: (data as ExpatHealthPoliciesData | undefined)
          ?.totalExpatPolicies,
      };

    case 'INCOMING_ES':
      return {
        policies: (data as ExpatSpainPoliciesData | undefined)
          ?.expatSpainPolicies as {
          id: string;
          policyNumber: string;
          user?: { firstName?: string; lastName?: string };
        }[],
        count: (data as ExpatSpainPoliciesData | undefined)
          ?.totalExpatSpainPolicies,
      };

    case 'PET_HEALTH':
      return {
        policies: (data as GenericPoliciesData | undefined)
          ?.genericPolicies as {
          id: string;
          policyNumber: string;
          user?: { firstName?: string; lastName?: string };
        }[],
        count: (data as GenericPoliciesData | undefined)?.genericPolicies,
      };

    case 'HOUSEHOLD':
      return {
        policies: (data as HouseholdPoliciesData | undefined)
          ?.householdPolicies as {
          id: string;
          policyNumber: string;
          user?: { firstName?: string; lastName?: string };
        }[],
        count: (data as HouseholdPoliciesData | undefined)
          ?.totalHouseholdPolicies,
      };
  }
};
