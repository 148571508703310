import { claimAssessmentFileTypes } from 'models/ClaimAssessment';
import { z } from 'zod';

export const updateClaimAssessmentDocumentSchema = z.object({
  id: z.string().uuid(),
  friendlyName: z.string().optional(),
  type: z.enum(claimAssessmentFileTypes, {
    required_error: 'This field is required',
  }),
  isVisibleToCustomer: z.boolean(),
  internalNote: z.string().optional(),
});
