import { CreditCardIcon } from '@heroicons/react/outline';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';

import type { NavigationLink } from '../NavBar';

export const paymentsLinks: NavigationLink = {
  name: 'Payment',
  icon: CreditCardIcon,
  id: 'payments',
  children: [
    {
      name: 'Expat health',
      href: generatePath(paths.payments.path, {
        insuranceType: 'expat-health',
      }),
      id: 'payments-expat-health',
    },
    {
      name: 'Bike',
      href: generatePath(paths.payments.path, {
        insuranceType: 'bike',
      }),
      id: 'payments-bike',
    },
    {
      name: 'Dental',
      href: generatePath(paths.payments.path, {
        insuranceType: 'dental',
      }),
      id: 'payments-dental',
    },
    {
      name: 'Pet health',
      href: generatePath(paths.payments.path, {
        insuranceType: 'pet-health',
      }),
      id: 'payments-pet-health',
    },
    {
      name: 'Household',
      href: generatePath(paths.payments.path, {
        insuranceType: 'household',
      }),
      id: 'payments-household',
    },
  ],
};

export const spainPaymentsLinks: NavigationLink = {
  name: 'Payment',
  icon: CreditCardIcon,
  id: 'payments',
  children: [
    {
      name: 'Expat health',
      href: generatePath(paths.payments.path, {
        insuranceType: 'expat-health-es',
      }),
      id: 'payments-expat-health-es',
    },
  ],
};
