import { DocumentNode, useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { capitalizeName } from '@getpopsure/public-utility';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Loader from 'components/Loader';
import {
  updateCreateNoteCache,
  updateDeleteNoteCache,
} from 'components/Notes/apollo/cacheModifiers';
import {
  CREATE_NOTE,
  DELETE_NOTE,
  UPDATE_NOTE,
} from 'components/Notes/graphql/Notes.mutations';
import { GET_NOTES } from 'components/Notes/graphql/Notes.queries';
import { NoteType } from 'components/Notes/models/models';
import { RichTextEditor } from 'components/RichTextEditor';
import { RichTextPreview } from 'components/RichTextEditor/components/RichTextPreview';
import dayjs from 'dayjs';
import { DATE_FORMAT_TIME } from 'models/date';
import { Note } from 'models/Note';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { PinIcon } from '../../../icons/PinIcon';
import { ActionsButton } from './components/ActionsButton/ActionsButton';
import { EditableTextarea } from './components/EditableTextarea/EditableTextarea';
import * as styles from './styles';

interface Props {
  note: Note;
  adminUserId?: string;
  refetchQueries?: DocumentNode[];
  entityId: string;
  type: NoteType;
}

export const NotesCard = ({
  note: parentNote,
  refetchQueries,
  adminUserId,
  entityId,
  type,
}: Props) => {
  const { t } = useTranslation();

  const {
    id: parentNoteId,
    adminUser: parentNoteAuthor,
    createdAt: parentNoteCreatedAt,
    body: parentNoteBody,
    pinned: parentNotePinned,
    updatedAt: parentNoteUpdatedAt,
    replyNotes,
  } = parentNote;

  // Note id selected for editing
  const [editNoteId, setEditNoteId] = useState<string | null>(null);

  // New editable note content
  const [editableNoteContent, setEditableNoteContent] = useState('');

  // New reply
  const [replyContent, setReplyContent] = useState('');

  const onEdit = ({ noteId, body }: { noteId: string; body: string }) => {
    setEditNoteId(noteId);
    setEditableNoteContent(body);
  };

  const onCancel = () => {
    setEditNoteId(null);
  };

  const alertBannersState = useReactiveVar(alertBanners);

  const onRequestErrored = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const reset = () => {
    setEditNoteId(null);
    setReplyContent('');
  };

  const onRequestCompleted = (message: string) => () => {
    reset();

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message,
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  // Update note ----------------------------------------------------
  const [updateNote, { loading: updateNoteLoading }] = useMutation<Note>(
    UPDATE_NOTE,
    {
      onCompleted: onRequestCompleted('Note updated successfully'),
      onError: onRequestErrored,

      /**
       * TODO: [KONG] Look into how to update cache for nested queries
       */
      refetchQueries,
    }
  );

  const onUpdateNote = ({
    updateNoteId,
    pinned,
    body,
  }: {
    updateNoteId: string;
    pinned?: boolean;
    body?: string;
  }) => {
    if (pinned === undefined && !body) {
      throw new Error('[Notes] No information to update note');
    }

    updateNote({
      variables: {
        noteId: updateNoteId,
        ...(pinned !== undefined && { pinned }),
        ...(body && { body }),
      },
    });
  };
  //----------------------------------------------------------------

  // Delete note ----------------------------------------------------
  const [deleteNote, { loading: deleteNoteLoading }] = useMutation<Note>(
    DELETE_NOTE,
    {
      onCompleted: onRequestCompleted('Note deleted successfully'),
      onError: onRequestErrored,
      /**
       * TODO: [KONG] Look into how to update cache for nested queries
       */
      refetchQueries: [...(refetchQueries ?? []), GET_NOTES],
      update: updateDeleteNoteCache,
    }
  );

  const onDelete = (noteId: string) => {
    deleteNote({ variables: { noteId } });
  };
  //----------------------------------------------------------------

  // Create reply notes -----------------------------------------------------
  const [createNote, { loading: createNoteLoading }] = useMutation<Note>(
    CREATE_NOTE,
    {
      onCompleted: onRequestCompleted('Note created successfully'),
      onError: onRequestErrored,

      update: updateCreateNoteCache,
    }
  );

  const onCreate = () => {
    createNote({
      variables: {
        entityId,
        entityType: type,
        parentNoteId,
        body: replyContent,
      },
    });
  };
  //----------------------------------------------------------------

  const firstNameOfParentNoteAuthor = parentNoteAuthor?.firstName;
  const lastNameOfParentNoteAuthor = parentNoteAuthor?.lastName;
  const nameOfParentNoteAuthor =
    firstNameOfParentNoteAuthor && lastNameOfParentNoteAuthor
      ? capitalizeName({
          firstName: firstNameOfParentNoteAuthor,
          lastName: lastNameOfParentNoteAuthor,
        }) ?? ''
      : '';
  const isAuthorOfParentNote = adminUserId === parentNoteAuthor?.id;
  const dateTimeToDisplayOfParentNote =
    parentNoteUpdatedAt || parentNoteCreatedAt;

  const isLoading = updateNoteLoading ?? deleteNoteLoading ?? createNoteLoading;

  const isParentNoteInEdit = editNoteId === parentNoteId;

  return (
    <div className={styles.noteCard}>
      <header className={styles.header}>
        <h3 className={styles.name}>
          {nameOfParentNoteAuthor ?? t('notes.notesCard.noName', 'No name')}
        </h3>
        <div className={styles.headerRight}>
          {/* Pin button */}
          <button
            className={classNames(styles.iconButton, {
              'text-indigo-500': parentNotePinned,
              'text-gray-500': !parentNotePinned,
            })}
            type="button"
            onClick={() => {
              onUpdateNote({
                updateNoteId: parentNoteId,
                pinned: !parentNotePinned,
              });
            }}
            data-testid="pin-icon-button"
          >
            <PinIcon pinned={parentNotePinned} />
          </button>

          <ActionsButton
            isDisabled={isLoading || !isAuthorOfParentNote}
            onEdit={() =>
              onEdit({ noteId: parentNoteId, body: parentNoteBody ?? '' })
            }
            onDelete={() => onDelete(parentNoteId)}
            authorName={nameOfParentNoteAuthor}
            createdAt={parentNoteCreatedAt ?? ''}
            deleteNoteLoading={deleteNoteLoading}
          />
        </div>
      </header>

      {parentNoteBody && !isParentNoteInEdit && (
        <div className="mt-[8px]">
          <RichTextPreview dangerouslySetHtml={parentNoteBody} />
        </div>
      )}

      {dateTimeToDisplayOfParentNote && !isParentNoteInEdit && (
        <div className={styles.date}>
          {dayjs(dateTimeToDisplayOfParentNote).format(DATE_FORMAT_TIME)}{' '}
          {parentNoteUpdatedAt && '(edited)'}
        </div>
      )}

      {isParentNoteInEdit && (
        <EditableTextarea
          onCancel={onCancel}
          onUpdate={() =>
            onUpdateNote({
              updateNoteId: editNoteId,
              body: editableNoteContent,
            })
          }
          value={editableNoteContent}
          onChange={setEditableNoteContent}
          isLoading={isLoading}
        />
      )}

      <div
        className={classNames({
          'pointer-events-none': isParentNoteInEdit,
        })}
      >
        {replyNotes?.map(
          (
            {
              id: replyNoteId,
              body: replyBody,
              adminUser: replyAuthor,
              createdAt: replyCreatedAt,
              updatedAt: replyUpdatedAt,
            },
            idx
          ) => {
            const firstNameOfReplyAuthor = replyAuthor?.firstName;
            const lastNameOfReplyAuthor = replyAuthor?.lastName;
            const nameOfReplyAuthor =
              firstNameOfReplyAuthor && lastNameOfReplyAuthor
                ? capitalizeName({
                    firstName: firstNameOfReplyAuthor,
                    lastName: lastNameOfReplyAuthor,
                  }) ?? ''
                : '';
            const isAuthorOfReplyNote = replyAuthor?.id === adminUserId;
            const dateTimeToDisplayOfReply = replyUpdatedAt ?? replyCreatedAt;

            const isReplyNoteInEdit = editNoteId === replyNoteId;

            const zIndex = replyNotes.length - idx;

            return (
              <div
                key={replyNoteId}
                className={styles.replyContainer}
                /**
                 * Dynamically setting z-index to the class name requires a re-render,
                 * so z-index is set to the style attr directly
                 */
                style={{ zIndex }}
              >
                <div className={styles.greyDotContainer}>
                  <div className={styles.replyGreyDot} />
                  <div className={styles.greyLine} />
                </div>

                <div className={styles.replyContentContainer}>
                  <header className={styles.header}>
                    <h3
                      className={classNames(styles.name, {
                        'text-gray-400': isParentNoteInEdit,
                      })}
                    >
                      {nameOfReplyAuthor ??
                        t('notes.notesCard.reply.noName', 'No name')}
                    </h3>
                    <div className={styles.headerRight}>
                      <ActionsButton
                        isDisabled={isLoading || !isAuthorOfReplyNote}
                        onEdit={() =>
                          onEdit({
                            noteId: replyNoteId,
                            body: replyBody ?? '',
                          })
                        }
                        onDelete={() => onDelete(replyNoteId)}
                        authorName={nameOfReplyAuthor}
                        createdAt={replyCreatedAt ?? ''}
                        deleteNoteLoading={deleteNoteLoading}
                      />
                    </div>
                  </header>

                  {replyBody && !isReplyNoteInEdit && (
                    <div className="mt-[8px]">
                      <RichTextPreview dangerouslySetHtml={replyBody} />
                    </div>
                  )}

                  {dateTimeToDisplayOfReply && !isReplyNoteInEdit && (
                    <div
                      className={classNames(styles.date, {
                        'text-gray-400': isParentNoteInEdit,
                      })}
                    >
                      {dayjs(dateTimeToDisplayOfReply).format(DATE_FORMAT_TIME)}{' '}
                      {replyUpdatedAt && '(edited)'}
                    </div>
                  )}

                  {isReplyNoteInEdit && (
                    <EditableTextarea
                      onCancel={onCancel}
                      onUpdate={() =>
                        onUpdateNote({
                          updateNoteId: editNoteId,
                          body: editableNoteContent,
                        })
                      }
                      value={editableNoteContent}
                      onChange={setEditableNoteContent}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </div>
            );
          }
        )}

        {/* Leave a comment section */}
        <div className={styles.divider} />

        <div className={styles.replyTextAreaContainer}>
          <RichTextEditor
            className={classNames(styles.replyTextArea, {
              'text-gray-400': isParentNoteInEdit,
              'placeholder-gray-400': isParentNoteInEdit,
            })}
            value={replyContent}
            setValue={setReplyContent}
            placeholder={t(
              'notes.notesCard.reply.textarea.placeholder',
              'Leave a comment'
            )}
          />

          <button
            className={classNames(styles.createReplyNoteButton, {
              [styles.disabledCreateReplyNoteButton]:
                !replyContent || isLoading,
            })}
            type="button"
            onClick={onCreate}
            disabled={!replyContent}
            aria-label="create reply note"
            data-testid="create-reply-button"
          >
            {isLoading ? (
              <Loader
                className={styles.createNoteLoader}
                primaryColor="stroke-white"
              />
            ) : (
              <PaperAirplaneIcon className={styles.createReplyNoteButtonIcon} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
