import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  ClaimAssessmentStatus,
  ClaimAssessmentStatuses,
} from 'shared/claimAssessments/models';
import { claimAssessmentStatusNameMapper } from 'shared/claimAssessments/statusNameMapper';

const statusOptions: SelectButtonOption<ClaimAssessmentStatus>[] =
  ClaimAssessmentStatuses.map((status) => ({
    id: status,
    label: claimAssessmentStatusNameMapper[status] ?? '',
    title: claimAssessmentStatusNameMapper[status] ?? '',
  }));

export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
