import { ClaimAssessmentStatuses } from 'shared/claimAssessments/models';
import { z } from 'zod';

const requiredError = 'This field is required.';

export const ZSubmittableClaimAssessmentSchema = z.object({
  userPolicyId: z.string().min(1, { message: requiredError }),
  status: z.enum(ClaimAssessmentStatuses),
  title: z.string().min(1, { message: requiredError }),
  doctorName: z.string().optional(),
  doctorEmail: z.string().optional(),
  diagnosis: z.string().optional(),
  uploadDocument: z.custom<File>().optional(),
});

export type SubmittableClaimAssessment = z.infer<
  typeof ZSubmittableClaimAssessmentSchema
>;
