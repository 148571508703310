import { useParams } from 'react-router-dom';
import { Country } from 'shared/countries/models';

import Page404 from '../../errors/404';
import { ClaimAssessmentsData } from '../index';
import { ClaimAssessmentsOverview } from './components/overview';

export const ClaimAssessmentsTemplate = () => {
  const { country } = useParams<{
    country: Country;
  }>();

  if (!country) {
    return <Page404 />;
  }

  return <ClaimAssessmentsOverview data={ClaimAssessmentsData} />;
};
