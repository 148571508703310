import { GET_ALL_POLICIES } from '../policies/all/graphql/policies';
import { CREATE_CLAIM_ASSESSMENT } from './modules/createClaimAssessment/graphql/mutations';
import { ZSubmittableClaimAssessmentSchema } from './modules/createClaimAssessment/models';
import {
  LINK_CLAIMS_TO_CLAIM_ASSESSMENT,
  UPDATE_CLAIM_ASSESSMENT,
  UPDATE_CLAIM_ASSESSMENT_STATUS,
} from './modules/details/graphql/mutations';
import {
  GET_CLAIM_ASSESSMENT,
  GET_CLAIMS_BY_POLICY_ID,
} from './modules/details/graphql/queries';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { TableHeaders } from './modules/overview/data/tableHeaders';
import { GET_CLAIM_ASSESSMENTS } from './modules/overview/graphql/queries';
import { retrieveClaimAssessmentInfoRows } from './template/components/details/components/ClaimAssessmentInfo/data';
import {
  claimAssessmentStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './template/components/details/components/StatusUpdate/data';
import { ClaimAssessmentsTemplateData } from './template/models';

export const ClaimAssessmentsData: ClaimAssessmentsTemplateData = {
  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaimAssessments: GET_CLAIM_ASSESSMENTS,
    fetchSingleClaimAssessment: GET_CLAIM_ASSESSMENT,
    fetchAllPolicies: GET_ALL_POLICIES,
    fetchClaimsByPolicyId: GET_CLAIMS_BY_POLICY_ID,
  },

  mutations: {
    createClaimAssessment: CREATE_CLAIM_ASSESSMENT,
    updateClaimAssessment: UPDATE_CLAIM_ASSESSMENT,
    updateClaimAssessmentStatus: UPDATE_CLAIM_ASSESSMENT_STATUS,
    linkClaimsToClaimAssessment: LINK_CLAIMS_TO_CLAIM_ASSESSMENT,
  },

  // Features ----------------------------------------
  overview: {
    headers: TableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimAssessmentCreation: {
    createClaimAssessmentSubmissionSchema: ZSubmittableClaimAssessmentSchema,
  },
  claimAssessmentInfo: {
    retrieveClaimAssessmentInfoRows,
  },
  statusUpdate: {
    statusOptions: claimAssessmentStatusOptions,
    dataByStatus: retrieveFormAndFooterTextByStatus,
  },
};
