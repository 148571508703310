import classNames from 'classnames';
import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';

import * as styles from './styles';

interface CompanyPolicyCardProps {
  title: string;
  icon: string;
  status: string;
  badgeColor: BadgeColor;
}

export const CompanyPolicyCard = ({
  title,
  icon,
  status,
  badgeColor,
}: CompanyPolicyCardProps) => {
  return (
    <div className={styles.policyCard}>
      <div className={styles.leftContainer}>
        <img className={styles.icon} src={icon} alt="insurance icon" />
        <div className={classNames(styles.titleContainer)}>
          <h5 className={styles.title}>{title}</h5>
        </div>
      </div>
      <div className={styles.rightContainer}>
        {status && badgeColor && (
          <div className={styles.status}>
            <Badge color={badgeColor} badgeType="full">
              {status}
            </Badge>
          </div>
        )}
      </div>
    </div>
  );
};
