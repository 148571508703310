import { gql } from '@apollo/client';

export const GET_ALL_POLICIES = gql`
  query GetAllPolicies(
    $insuranceTypes: [String!]!
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortOrder: String!
  ) {
    allPolicies(
      insuranceTypes: $insuranceTypes
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortOrder: $sortOrder
    ) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      policyNumber
    }
    allPoliciesCount(
      insuranceTypes: $insuranceTypes
      searchString: $searchString
    )
  }
`;
