import { ClipboardListIcon } from '@heroicons/react/outline';

import { retrieveClaimAssessmentsOverviewPath } from '../../../pages/claimAssessments/template/utils/utils';
import type { NavigationLink } from '../NavBar';

export const germanyClaimAssessmentsLinks: NavigationLink = {
  name: 'APVs',
  icon: ClipboardListIcon,
  id: 'claim-assessments-de',
  href: retrieveClaimAssessmentsOverviewPath(),
};
