import { useMutation, useReactiveVar } from '@apollo/client';
import { SelectButtonOption } from 'components/SelectButton';
import { StatusDropdown } from 'components/StatusDropdown';
import { StatusUpdateModal } from 'components/StatusUpdateModal';
import { retrieveValidationByType } from 'components/StatusUpdateModal/utils';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { useState } from 'react';
import { ClaimAssessmentStatus } from 'shared/claimAssessments/models';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { UPDATE_CLAIM_ASSESSMENT_STATUS } from '../../../../../modules/details/graphql/mutations';
import { GET_CLAIM_ASSESSMENT } from '../../../../../modules/details/graphql/queries';
import { StatusData } from '../../../../models';

interface Props {
  claimAssessmentId: string;
  claimAssessmentStatus?: ClaimAssessmentStatus;
  statusOptions: SelectButtonOption<ClaimAssessmentStatus>[];
  dataByStatus: (status: ClaimAssessmentStatus) => StatusData;
  userPolicyId: string;
  createdOn?: string;
}

export const StatusUpdate = <Form extends Record<string, any>>({
  claimAssessmentId,
  claimAssessmentStatus,
  dataByStatus,
  statusOptions,
  userPolicyId,
  createdOn,
}: Props) => {
  const currentStatus = statusOptions.find(
    ({ id }) => id === claimAssessmentStatus
  );
  if (!currentStatus) {
    throw new Error('[APV status update] New status not found');
  }

  const [selectedStatus, setSelectedStatus] =
    useState<SelectButtonOption<ClaimAssessmentStatus>>(currentStatus);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [updateClaimAssessmentStatus, { loading }] = useMutation(
    UPDATE_CLAIM_ASSESSMENT_STATUS
  );

  const { footerText, form } = dataByStatus(selectedStatus.id);

  const onStatusChange = (
    option: SelectButtonOption<ClaimAssessmentStatus>
  ) => {
    if (option.id !== currentStatus?.id) {
      setSelectedStatus(option);
      setIsStatusModalOpen(true);
    }
  };

  const alertBannersState = useReactiveVar(alertBanners);

  const onStatusUpdateComplete = () => {
    setIsStatusModalOpen(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'Status successfully updated',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onStatusUpdate = (form?: Record<string, any>) => {
    if (!selectedStatus?.id) {
      throw new Error('[APV status update] No new status to submit');
    }

    if (!userPolicyId) {
      throw new Error('[APV status update] Policy info not found');
    }

    if (!createdOn) {
      throw new Error('[APV status update] Created date not found');
    }

    updateClaimAssessmentStatus({
      variables: {
        // Other collected fields from form
        ...form,

        // Mandatory fields for all statuses
        claimAssessmentId,
        status: selectedStatus.id,
        userPolicyId,
        createdOn: dayjs(createdOn).format(DATE_FORMAT),
      },
      onCompleted: onStatusUpdateComplete,
      refetchQueries: [GET_CLAIM_ASSESSMENT],
    });
  };

  const validatorObject = form.reduce(
    (acc, { id, required, data: { type } }) => {
      const validation = retrieveValidationByType(type);
      return {
        ...acc,
        ...(validation && {
          [id]: required ? validation : validation?.optional(),
        }),
      };
    },
    {}
  );

  const validationSchema = z.object({
    ...validatorObject,
  });

  return (
    <>
      {selectedStatus && (
        <StatusUpdateModal<Form, ClaimAssessmentStatus>
          isOpen={isStatusModalOpen}
          setOpen={setIsStatusModalOpen}
          onSubmit={onStatusUpdate}
          form={form}
          status={selectedStatus}
          bottomText={footerText}
          validationSchema={validationSchema}
          loading={loading}
          scrollable={true}
        />
      )}
      <StatusDropdown<ClaimAssessmentStatus>
        options={statusOptions}
        onChange={onStatusChange}
        status={currentStatus}
      />
    </>
  );
};
